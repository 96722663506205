import React, { Component } from "react";
import "../App.css";
import Config from "../config";
import { Row, Col } from "react-bootstrap";
import { ReactSession } from "react-client-session";
import { WelcomeModal } from "../components/welcome-modal";
import ChangeCard from "./login-card";
import NotChangeableFields from "./not-login-fields";
import Preloader from "./Preloader";
import { LimitModal } from "./limit-modal";

export default class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // статус происходящего
      statusAuth: "",
      // идет ли загрузка, по умолчанию идет пока подгружаем данные визитки
      loading: true,
      // идет ли загрузка фотки
      loading_img: false,
      // для изменения цвета статуса
      text_class: "adm-status-auth",
      // для хранения адреса фото
      url: "",
      // поля, которые должны быть инициализированы по окончанию загрузки страницы
      fields: {},
      // из ссылки вытягиваем uid карточки
      uid: window.location.pathname.split("/")[2],
      // отображать ли приветсветнное сообщение
      // только при первом открытии ссылки с визиткой
      welcomeModalShow: false,
      limitModalShow: false,
    };
    // временная переменная для хранения промежуточных полей
    // (чтобы избежать перезагрузки страницы\изменения стейта до того, как будет загружено фото)
    this.fields_temp = null;
    this.url_temp = "";
    // количество полей для отслеживания пустоты визитки
    this.count_fields = 0;

    // залогинен ли пользователь
    this.isLogin = false;
    // переадресация на страницу регистрации
    this.routeToRegister = this.routeToRegister.bind(this);
    // обнуляем переменную в сессии
    ReactSession.set("user_token", "");
  }

  // каждый раз перед рендерингом страницы загружаем визитку с базы
  componentDidMount() {
    this.getCard();
  }

  // запрос к серверу для поиска и загрузки визитки
  getCard() {
    fetch(`${Config.BACKEND_IP}/api/card/${this.state.uid}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(
        (data) => {
          if (data.status === "Card not found") {
            // если такой карты не существует, значит делаем переадресацию на 404 страницу
            console.log(`Card not found.`);
            window.location.assign(`/card-not-found`);
          } else {
            // временно сохраняем данные
            this.fields_temp = { ...data, isLimit: false };

            if (data.status === "MAX_READING_EXCEEDED") {
              this.fields_temp.isLimit = true;
            }

            this.count_fields = data.infos.length;
            // сохраняем uid
            ReactSession.set("uid", this.state.uid);
            // если фотка есть в визитке, значит грузим фотку
            if (
              data.photoId !== null &&
              data.photoId !== "" &&
              this.state.fields.photoId !== data.photoId
            ) {
              // формируем заголовок страницы (для вкладки)
              let name = "";
              let surname = "";
              let title_name = "vCard";
              data.infos.map((field) =>
                field.keyName === "NAME"
                  ? (name = field.data)
                  : field.keyName === "SURNAME"
                  ? (surname = field.data)
                  : null
              );
              title_name +=
                name !== ""
                  ? ` - ${name} ${surname}`
                  : surname !== ""
                  ? ` - ${surname}`
                  : "";
              document.title = title_name;

              //грузим фото
              this.getPhotoCard(data.photoId);
            } else {
              // формируем заголовок страницы (для вкладки)
              let title_name = "";
              data.infos.map((field) =>
                field.keyName === "NAME"
                  ? (title_name += field.data)
                  : field.keyName === "SURNAME"
                  ? (title_name += field.data)
                  : null
              );
              document.title = title_name;

              // определяем, залогинен ли пользователь
              // если токен есть в сессии и он не пустой, то залогинен, иначе нет
              this.isLogin =
                ReactSession.get("user_token") !== undefined &&
                ReactSession.get("user_token") !== "" &&
                ReactSession.get("user_token") !== null
                  ? true
                  : false;

              // сохраняем поля в состоянии, определяем отображать ли приветствие и выключаем статус загрузки
              this.setState({
                fields: this.fields_temp,
                loading: false,
                welcomeModalShow: ReactSession.get("welcome"),
                limitModalShow: this.fields_temp.isLimit,
              });

              // прячем прелоадер
              document.body.classList.add("loaded_hiding");
              window.setTimeout(function () {
                document.body.classList.add("loaded");
                document.body.classList.remove("loaded_hiding");
              }, 500);
            }
            console.log(`Card loaded succsessfully.`);
          }
        },
        (error) => {
          console.log(
            `An unexpected error occurred while loading the card: ${error}.`
          );
          window.location.assign(`/card-not-found`);
        }
      );
  }

  // запрос на загрузку фото, делается после загрузки полей только если фото есть в визитке
  getPhotoCard(photoId) {
    fetch(`${Config.BACKEND_IP}/api/photo/${photoId}`, {
      method: "GET",
    })
      .then((response) => response.url)
      .then(
        (photo) => {
          // временно сохраняем ссылку на фото
          this.url_temp = photo;

          console.log(`Фото загружено успешно.`);
          // определяем, залогинен ли пользователь
          // если токен есть в сессии и он не пустой, то залогинен, иначе нет
          this.isLogin =
            ReactSession.get("user_token") !== undefined &&
            ReactSession.get("user_token") !== "" &&
            ReactSession.get("user_token") !== null
              ? true
              : false;

          // сохраняем поля и ссылку на фото в состоянии,
          // определяем отображать ли приветствие и выключаем статус загрузки
          this.setState({
            fields: this.fields_temp,
            loading: false,
            url: this.url_temp,
            welcomeModalShow: ReactSession.get("welcome"),
            limitModalShow: this.fields_temp.isLimit,
          });

          // прячем прелоадер
          document.body.classList.add("loaded_hiding");
          window.setTimeout(function () {
            document.body.classList.add("loaded");
            document.body.classList.remove("loaded_hiding");
          }, 500);
        },
        (error) => {
          console.log(
            `An unexpected error occurred while loading the photo: ${error}.`
          );
        }
      );
  }

  // сортируем поля по заданому шаблону
  sortFields(type) {
    // тупой но эффективный способ
    // храним по переменной на каждый вид полей и записываем их туда
    // потом просто перезаписываем состояние с таким шаблоном

    // временная переменная с состоянием, чтобы не использовать его
    let newState = this.state;
    // временная переменная с полями (и фото, и другими не отображаемыми данными)
    let newFields = newState.fields;
    // временная переменная с полями (только для отображения)
    let infos = newFields.infos;

    let sort_infos = [];

    let name = null;
    let surname = null;
    let patronymic = null;

    let org = null;
    let title = null;

    let bday = null;
    let note = null;

    let numbers = [];
    let emails = [];
    let urls = [];
    let adr = [];

    let facebook = null;
    let linkedin = null;
    let instagram = null;
    let telegram = null;
    let youtube = null;
    let viber = null;
    let whatsapp = null;
    let twitter = null;
    let tiktok = null;

    // если количество полей больше нуля, значит перебираем поля
    // если тип соответствует, то сохраняем значение в соответствующую переменную
    if (this.state.fields.infos.length !== 0) {
      infos.map((field) =>
        field.keyName === "NAME"
          ? (name = field)
          : field.keyName === "SURNAME"
          ? (surname = field)
          : field.keyName === "PATRONYMIC"
          ? (patronymic = field)
          : field.keyName === "ORG"
          ? (org = field)
          : field.keyName === "TITLE"
          ? (title = field)
          : field.keyName === "BDAY"
          ? (bday = field)
          : field.keyName === "TEL"
          ? numbers.push(field)
          : field.keyName === "EMAIL"
          ? emails.push(field)
          : field.keyName === "URL"
          ? urls.push(field)
          : // если тип адрес, определяем залогинен ли юзер
          // если да, то превращаем строку через ";" в обьект
          // если нет, то превращаем в строку через ","
          field.keyName === "ADR"
          ? type === "login"
            ? adr.push(this.adr_StringBackToObj(field))
            : adr.push(this.adr_StringBackToStringShow(field))
          : field.keyName === "NOTE"
          ? (note = field)
          : field.keyName === "SOCIAL" && field.type === "FACEBOOK"
          ? (facebook = field)
          : field.keyName === "SOCIAL" && field.type === "LINKEDIN"
          ? (linkedin = field)
          : field.keyName === "SOCIAL" && field.type === "INSTAGRAM"
          ? (instagram = field)
          : field.keyName === "SOCIAL" && field.type === "TELEGRAM"
          ? (telegram = field)
          : field.keyName === "SOCIAL" && field.type === "YOUTUBE"
          ? (youtube = field)
          : field.keyName === "SOCIAL" && field.type === "VIBER"
          ? (viber = field)
          : field.keyName === "SOCIAL" && field.type === "WHATSAPP"
          ? (whatsapp = field)
          : field.keyName === "SOCIAL" && field.type === "TWITTER"
          ? (twitter = field)
          : field.keyName === "SOCIAL" && field.type === "TIKTOK"
          ? (tiktok = field)
          : null
      );

      // начинаем формировать новую последовательность полей
      sort_infos.push(name);
      sort_infos.push(surname);
      sort_infos.push(patronymic);

      sort_infos.push(org);
      sort_infos.push(title);

      sort_infos = sort_infos.concat(numbers);
      sort_infos = sort_infos.concat(emails);
      sort_infos = sort_infos.concat(adr);

      sort_infos.push(bday);

      sort_infos = sort_infos.concat(urls);

      sort_infos.push(telegram);
      sort_infos.push(linkedin);
      sort_infos.push(facebook);
      sort_infos.push(instagram);
      sort_infos.push(twitter);
      sort_infos.push(youtube);
      sort_infos.push(tiktok);
      sort_infos.push(viber);
      sort_infos.push(whatsapp);

      sort_infos.push(note);

      // убираем нулевые поля
      sort_infos = sort_infos.filter((element) => element !== null);

      newFields.infos = sort_infos;

      // если пользователь залогинен
      // возвращаем новые поля с другими данными
      // иначе только поля
      if (this.isLogin) {
        newState.fields = newFields;
        return newState;
      } else {
        return newFields.infos;
      }
    }
    // если количество полей = 0, значит возвращаем их же (пустые)
    else {
      if (this.isLogin) {
        return this.state;
      } else {
        return this.state.fields.infos;
      }
    }
  }

  // превращение строки от сервера в строку для отображения
  // убираем лишние пробелы и символы, заменяем ; на ,
  adr_StringBackToStringShow(back_adr) {
    let adr = back_adr.data;

    //del space
    adr = adr.replace(/\s/g, "").trim();

    //del repeat ;
    adr = adr.replace(/;+(?=;)/g, "");
    if (adr[0] === ";") {
      adr = adr.slice(1, adr.length);
    }

    //change ; to ,
    adr = adr.replace(/;/g, ", ");

    back_adr.data = adr;
    return back_adr;
  }

  // превращение строки от сервера в обьект
  adr_StringBackToObj(back_adr) {
    // перебираем строку и парсим по ;
    // разбиваем на массив и потом формируем обьект
    let temp = back_adr.data.split(";");

    back_adr.data = {
      address: temp[0],
      city: temp[1],
      region: temp[2],
      postal_code: temp[3],
      country: temp[4],
    };
    return back_adr;
  }

  // загружаем vcard по нажатию на кнопку
  getVcard(event) {
    event.preventDefault();
    // создаем ссылку, записываем в нее адрес и имитируем нажатие
    const link = document.createElement("a");
    link.href = `${Config.BACKEND_IP}/api/file/${this.state.uid}`;
    link.click();
  }

  //переадресация на страницу регистрации
  // если карта пустая и у нее еще нет пароля
  // то есть, сразу после покупки
  routeToRegister() {
    window.location.assign(`/Registration`);
  }

  // проверяем не пустая ли визитка
  checkEmptyFields() {
    for (let field of this.state.fields.infos) {
      // если хотя бы одно поле не пустое, визитка не пустая
      if (field.data !== "" && field.data !== null) {
        return false;
      }
    }
    return true;
  }

  // вернуть имя поля для отображения по его типу
  getFieldName(name_field) {
    if (Config.FIELDS_NAMES[name_field] !== null) {
      return Config.FIELDS_NAMES[name_field];
    } else {
      return null;
    }
  }

  // начинать отображение обьектов по центру или сначала
  setCenterOrStart() {
    // если у визитки нет фото, то сначала
    // иначе по центру
    if (this.state.url !== null && this.state.url !== "") {
      document
        .getElementsByClassName("main-container")[0]
        .classList.add("aligh-item-start");
    } else {
      document
        .getElementsByClassName("main-container")[0]
        .classList.remove("aligh-item-start");
    }
  }

  render() {
    // тип хранения - сессия
    ReactSession.setStoreType("sessionStorage");

    // для закрытия модального окна
    let welcomeModalClose = () => {
      this.setState({ welcomeModalShow: false });
      ReactSession.set("welcome", false);
    };

    return (
      <div className="main-container">
        <div>
          {
            // рендерим прелоадер, но отображаем только пока не будет загружена визитка
          }
          <Preloader />

          {
            // рендерим модальное окно привествия, но отображаем только при первой загрузке визитки после регистрации
          }
          <WelcomeModal
            show={this.state.welcomeModalShow}
            onHide={welcomeModalClose}
          />

          <LimitModal show={this.state.limitModalShow} />

          {
            // если это первый вход на карту и пароля к ней еще нет
            // переадресация на страницу регистрации
            this.state.fields.firstLogin ? (
              this.routeToRegister()
            ) : // иначе
            // если пользователь авторизирован, то рендерим компонент login-card.js
            // если нет, то not-login-fields.js
            this.isLogin ? (
              // добавляем компонент login-card.js
              <ChangeCard
                // передаем в props сортированные поля
                // и функцию для получания имени полей
                stat={this.sortFields("login")}
                getFieldName={this.getFieldName}
              />
            ) : (
              //not login
              <div className="card-container">
                <Row>
                  <Col>
                    {
                      // если в визитке есть фото, то рендерим его по ссылке из состояния
                      this.state.url !== undefined && this.state.url !== "" ? (
                        <div className="img-shadow">
                          <img
                            className="img-url-center-notback"
                            src={this.state.url}
                            alt="user_image"
                          />
                        </div>
                      ) : null
                    }

                    {
                      // если сейчас не идет загрузка, то
                      // определяем, как нам рендерить поля
                      // и добавляем компонент not-login-fields.js
                      !this.state.loading ? (
                        <>
                          {this.setCenterOrStart()}
                          <NotChangeableFields
                            // передаем в props
                            // отсортированные поля (без фото и другой доп инфы)
                            fields={this.sortFields("not-login")}
                            // отображать ли кнопку "добавить в контакты"
                            isVCard={!this.checkEmptyFields()}
                            // функцию для получения имени полей
                            getFieldName={this.getFieldName}
                            // функцию для загрузки vcard
                            getVcard={this.getVcard.bind(this)}
                            // колличество полей
                            count_fields={this.count_fields}
                          />
                        </>
                      ) : null
                    }

                    {
                      // отображение статуса на странице
                      this.state.statusAuth !== "" ? (
                        // цвет текста зависит от класа text_class
                        <div className={this.state.text_class}>
                          {this.state.statusAuth}
                        </div>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
