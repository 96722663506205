import React from "react";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useReRender from "../../hooks/useReRender";

const LanguageButton = () => {
  const { i18n } = useTranslation();
  const language = useRef();
  const history = useHistory();
  const render = useReRender();

  const changeLanguage = () => {
    if (i18n.language === "ua" || i18n.language === "ru") {
      i18n.changeLanguage("eu");
      language.current = "EN";
      history.push("/en/");
    } else {
      i18n.changeLanguage("ua");
      language.current = "UA";
      history.push("/");
    }
  };

  const checkLanguage = () => {
    const isEN = i18n.language === "eu" || i18n.language === "en-US";
    const isUA = i18n.language === "ua";
    console.log(window.location.pathname, "|", i18n.language);
    if (
      (isEN && window.location.pathname === "/en/") ||
      (isUA && window.location.pathname === "/")
    ) {
      return true;
    } else if (window.location.pathname === "/en/") {
      i18n.changeLanguage("eu");
      language.current = "EN";
      history.push("/en/");
      return false;
    } else {
      i18n.changeLanguage("ua");
      language.current = "UA";
      history.push("/");
      return false;
    }
  };

  useEffect(() => {
    if (!language.current) {
      language.current =
        i18n.language === undefined || i18n.language === ""
          ? "en"
          : i18n.language === "ua"
          ? "UA"
          : "EN";
      render();
      checkLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      className="language-button"
      type="button"
      onClick={() => changeLanguage()}
      style={{ textTransform: "uppercase" }}
    >
      {language.current}
    </button>
  );
};

export default LanguageButton;
