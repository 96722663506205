import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "./Landing/styles.css";

import Registration from "./components/registration";
import Card from "./components/business-card";
import Authorization from "./components/authorization";
import AuthAdm from "./admin_panel/auth_adm";
import AddAdm from "./admin_panel/add_adm";
import ActionsAdm from "./admin_panel/actions_adm";
import CreateCardAdm from "./admin_panel/create_card_adm";
import CreateGoodsAuthAdm from "./admin_panel/create_goods_auth_adm";
import NotFound from "./components/not-found";
import ResetPassword from "./admin_panel/reset_pass";
import Landing from "./Landing/Landing";
import Preloader from "./components/Preloader";
import { Suspense } from "react";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta name="googlebot" content="noindex" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="vCard - NFC business card" />
        <title>vCard</title>
      </Helmet>
      <Suspense fallback={<Preloader />}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Landing} exact />
            <Route path="/en/" component={Landing} exact />
            <Route
              path="/c/:id"
              render={(props) => {
                return <Card {...props} />;
              }}
            />
            <Route path="/Registration" component={Registration} exact />
            <Route path="/Authorization" component={Authorization} exact />

            <Route path="/AdminPanel/Authorization" component={AuthAdm} exact />
            <Route path="/AdminPanel/Add" component={AddAdm} exact />
            <Route
              path="/AdminPanel/ResetPassword"
              component={ResetPassword}
              exact
            />
            <Route
              path="/AdminPanel/WriteCard"
              component={CreateCardAdm}
              exact
            />
            <Route
              path="/AdminPanel/WriteGoodsAuth"
              component={CreateGoodsAuthAdm}
              exact
            />
            <Route path="/AdminPanel/Actions" component={ActionsAdm} exact />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
