import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { BiLoader, BiShowAlt, BiHide } from "react-icons/bi";
import Config from "../config.js";
import { ReactSession } from "react-client-session";

export default class AddAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAuth: null,
      loading: false,
      text_class: "adm-status-auth",
      login_inp: "",
      password_inp: "",
      login_bool: false,
      password_bool: false,
      login_class: "form-control",
      password_class: "input-group-custom",
      hide_or_show_pass: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.setState({ loading: true });
    let token = `Bearer ${ReactSession.get("admin_token")}`;
    event.preventDefault();
    fetch(`${Config.BACKEND_IP}/api/admin/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        username: event.target.login.value,
        password: event.target.password.value,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.status === "Ok") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: `The administrator has been successfully added to the database.`,
            });
            console.log(
              "The administrator has been successfully added to the database."
            );
            setTimeout(window.location.assign(`/AdminPanel/Actions`), 2000);
            // window.location.assign(`/AdminPanel/Actions`);
          } else if (result.status === "Failed") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Error adding to database.`,
            });
            console.log(`Error adding to database: ${result.message}.`);
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Unexpected error while adding to database.`,
            });
            console.log(`Error adding to database: ${result.message}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `Unexpected error.`,
          });
          console.log(`Unexpected error: ${error}.`);
        }
      );
  }

  logoutAdm() {
    ReactSession.setStoreType("sessionStorage");

    if (
      ReactSession.get("admin_token") === undefined ||
      ReactSession.get("admin_token") === ""
    ) {
      window.location.assign(`/AdminPanel/Authorization`);
    }
  }

  handleChangeLogin(event) {
    event.preventDefault();
    let temp_login = event.target.value;
    if (event.target.value !== "") {
      if (event.target.value.length >= 5) {
        this.setState({
          login_inp: temp_login,
          login_bool: true,
          statusAuth: ``,
          login_class: "form-control form-true",
        });
      } else {
        this.setState({
          login_inp: temp_login,
          login_bool: false,
          text_class: "adm-status-auth",
          statusAuth: `Minimum login length: 5 characters.`,
          login_class: "form-control form-false",
        });
      }
    } else {
      this.setState({
        login_inp: temp_login,
        login_bool: false,
        statusAuth: ``,
        login_class: "form-control",
      });
    }
  }

  handleChangePassword(event) {
    event.preventDefault();
    let temp_pass = event.target.value;
    if (event.target.value !== "") {
      if (event.target.value.length >= 8) {
        if (
          event.target.value.match(/[0-9]+/) &&
          (event.target.value.match(/[a-z]+/) ||
            event.target.value.match(/[A-Z]+/))
        ) {
          this.setState({
            password_inp: temp_pass,
            password_bool: true,
            statusAuth: ``,
            password_class: "input-group-custom form-true",
          });
        } else {
          this.setState({
            password_inp: temp_pass,
            password_bool: false,
            text_class: "adm-status-auth",
            statusAuth: `The password must include at least one number and letter.`,
            password_class: "input-group-custom form-false",
          });
        }
      } else {
        this.setState({
          password_inp: temp_pass,
          password_bool: false,
          text_class: "adm-status-auth",
          statusAuth: `Minimum password length: 8 characters.`,
          password_class: "input-group-custom form-false",
        });
      }
    } else {
      this.setState({
        password_inp: temp_pass,
        password_bool: false,
        statusAuth: ``,
        password_class: "input-group-custom",
      });
    }
  }

  show_hide_password() {
    var input = document.getElementById("password");
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      this.setState({ hide_or_show_pass: false });
    } else {
      input.setAttribute("type", "password");
      this.setState({ hide_or_show_pass: true });
    }
    return false;
  }

  render() {
    this.logoutAdm();
    ReactSession.setStoreType("sessionStorage");
    return (
      <div className="main-container">
        {/* <BackButton/> */}
        <div className="adm-add-container">
          <Row>
            <Col>
              <div className="title-left-text adm">Add administrator</div>

              <Form onSubmit={this.handleSubmit} autoComplete="off">
                <Form.Group
                  controlId="login"
                  onChange={this.handleChangeLogin.bind(this)}
                >
                  <FloatingLabel
                    controlId="login"
                    label="Enter login"
                    className="adm-add-form-input"
                    value={this.state.login_inp}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter login"
                      className={this.state.login_class}
                      required
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  controlId="password"
                  className="authorization-form-input"
                  onChange={this.handleChangePassword.bind(this)}
                >
                  <InputGroup className={this.state.password_class}>
                    <FloatingLabel
                      controlId="password"
                      label="Enter password"
                      className="authorization-ff"
                      value={this.state.password_inp}
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        required
                        className="password-h-shw"
                      />
                    </FloatingLabel>
                    <button
                      className="hide-show-password"
                      onClick={this.show_hide_password.bind(this)}
                      type="button"
                    >
                      {this.state.hide_or_show_pass ? (
                        <BiShowAlt />
                      ) : (
                        <BiHide />
                      )}
                    </button>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {this.state.login_bool && this.state.password_bool ? (
                    !this.state.loading ? (
                      <button className="btn-filled" type="submit">
                        Add
                      </button>
                    ) : (
                      <button className="btn-filled" disabled>
                        <span>
                          Add{" "}
                          <span className="loadIcon">
                            <BiLoader />
                          </span>
                        </span>
                      </button>
                    )
                  ) : (
                    <button className="btn-outlined-dark" disabled>
                      Add
                    </button>
                  )}

                  {this.state.statusAuth != null ? (
                    <div className={this.state.text_class}>
                      {this.state.statusAuth}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
