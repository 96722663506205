import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { BiLoader, BiShowAlt, BiHide } from "react-icons/bi";
import { ReactSession } from "react-client-session";
import Config from "../config.js";

export default class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ошибки и другая информация, которая будет отображаться пользователю
      statusAuth: "",
      // идет ли сейчас загрузка (для отображения индикатора загрузки на кнопке)
      loading: false,
      // для изменения цвета информации (переменная выше)
      text_class: "adm-status-auth",
      // для изменения внешнего вида кнопки в зависимости от условий
      button_class: "btn-outlined-dark",
      // хранение пароля
      password_inp: "",
      // переменная для отслеживания валидации пароля
      password_bool: false,
      // для изменения внешнего вида границ пароля (красная\зеленая\серая обводка)
      password_class: "input-group-custom",
      // показывать или прятать пароль
      hide_or_show: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // отправка запроса на авторизацию на сервер
  handleSubmit(event) {
    // показываем, что начинается загрузка
    this.setState({ loading: true, statusAuth: "" });
    //сохраняем uid карты в сессии для дальнейшего использования
    let uid = ReactSession.get("uid");

    event.preventDefault();
    fetch(`${Config.BACKEND_IP}/api/auth`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: uid,
        password: event.target.password.value,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          // если нет ошибок и токен пользователя не равен пустоте, то авторизация успешна
          if (result.error !== "Failed Auth" && result.token !== "") {
            // загрузка закончена, отображаем информацию юзеру
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: `Authorization successful.`,
            });
            console.log(`Authorization successful.`);
            // сохраняем в сессию токен пользователя
            ReactSession.set("user_token", result.token);
            // открываем следующее окно с визиткой юзера
            window.location.assign(`/c/${uid}`);
          }
          // ответ сервера в случае, если пароль не верен
          else if (result.error === "Failed Auth") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Invalid password.`,
            });
            console.log(`Invalid password.`);
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Unexpected error.`,
            });
            console.log(`Unexpected error: ${result}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `Unexpected error.`,
          });
          console.log(`Unexpected error: ${error}.`);
        }
      );
  }

  // отслежитваем изменения пароля + валидация на количество символов
  handleChangePassword(event) {
    event.preventDefault();
    let temp_pass = event.target.value;
    this.setState({ password_inp: event.target.value });
    // если пароль меньше 8 символов - валидация не проходится, выводится сообщения красным цветом
    // иначе все хорошо, зеленый цвет
    if (temp_pass !== "" && temp_pass.length >= 8) {
      this.setState({
        password_bool: true,
        password_class: "input-group-custom form-true",
      });
    } else {
      this.setState({
        password_bool: false,
        password_class: "input-group-custom form-false",
      });
    }
  }

  // скрывать или отображать пароль как текст (по нажатию кнопки)
  show_hide_password() {
    var input = document.getElementById("password");
    // если пароль, то переводим в текст и наоборот
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      this.setState({ hide_or_show: false });
    } else {
      input.setAttribute("type", "password");
      this.setState({ hide_or_show: true });
    }
    return false;
  }

  render() {
    // задаем тип хранилища данные - сессия
    // создаем или обнуляем переменную с токеном юзера
    ReactSession.setStoreType("sessionStorage");
    ReactSession.set("user_token", "");

    return (
      <div className="main-container">
        <div className="authorization-container">
          <Row>
            <Col>
              <div className="title-left-text">Edit</div>

              <div className="description-left-text">
                Enter your personal password to start editing
              </div>

              <Form onSubmit={this.handleSubmit}>
                <Form.Group
                  controlId="password"
                  className="authorization-form-input"
                  onChange={this.handleChangePassword.bind(this)}
                >
                  <InputGroup className={this.state.password_class}>
                    <FloatingLabel
                      controlId="password"
                      label="Enter password"
                      className="authorization-ff"
                      value={this.state.password_inp}
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        required
                        className="password-h-shw"
                      />
                    </FloatingLabel>
                    {
                      // кнопка для изменения типа отображения пароля
                    }
                    <button
                      className="hide-show-password"
                      onClick={this.show_hide_password.bind(this)}
                      type="button"
                      tabIndex={-1}
                    >
                      {
                        // если показываем пароль, икона меняется и наоборот
                        this.state.hide_or_show ? <BiShowAlt /> : <BiHide />
                      }
                    </button>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {
                    // активна ли кнопка отправки запроса (подтверждение ввода пароля)
                    // если валидация пройдена
                    // загрузка не идет или закончена
                    // кнопка - активна
                    // иначе кнопка заблокирована
                    this.state.password_bool ? (
                      !this.state.loading ? (
                        <button
                          className="btn-filled low-margin-top"
                          type="submit"
                        >
                          Login
                        </button>
                      ) : (
                        <button className="btn-filled low-margin-top" disabled>
                          <span>
                            Login{" "}
                            <span className="loadIcon">
                              <BiLoader />
                            </span>
                          </span>
                        </button>
                      )
                    ) : (
                      <button
                        className="btn-outlined-dark low-margin-top"
                        disabled
                      >
                        Login
                      </button>
                    )
                  }

                  {/* <div className="forget_pass">
                                    <a href="/">Forgot your password?</a>
                                </div> */}

                  {
                    // отображение статуса авторизации
                    this.state.statusAuth !== "" ? (
                      <div className={this.state.text_class}>
                        {this.state.statusAuth}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
