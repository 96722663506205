import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { BiLoader, BiShowAlt, BiHide } from "react-icons/bi";
import Config from "../config.js";
import { ReactSession } from "react-client-session";

export default class AuthAdm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAuth: "",
      loading: false,
      text_class: "adm-status-auth",
      button_class: "btn-outlined-dark",
      login_inp: "",
      password_inp: "",
      login_bool: false,
      password_bool: false,
      login_class: "form-control",
      password_class: "input-group-custom",
      hide_or_show_pass: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.setState({ loading: true, statusAuth: "" });
    event.preventDefault();
    fetch(`${Config.BACKEND_IP}/api/auth`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: event.target.login.value,
        password: event.target.password.value,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.error !== "Failed Auth" && result.token !== "") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: `Welcome, ${event.target.login.value}.`,
            });
            ReactSession.set("admin_token", result.token);
            console.log(`Login succsessfully.`);
            window.location.assign(`/AdminPanel/Actions`);
          } else if (result.error === "Failed Auth") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Wrong login or password.`,
              login_class: "form-control form-false",
              password_class: "input-group-custom form-false",
            });
            console.log(`Wrong login or password.`);
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Unexpected error.`,
            });
            console.log(`Unexpected error: ${result}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `Unexpected error.`,
          });
          console.log(`Unexpected error: ${error}.`);
        }
      );
  }

  handleChangeLogin(event) {
    event.preventDefault();
    let temp_login = event.target.value;
    this.setState({ login_inp: event.target.value });
    if (temp_login !== "" && temp_login.length >= 5) {
      this.setState({
        login_inp: temp_login,
        login_bool: true,
        login_class: "form-control form-true",
      });
    } else {
      this.setState({
        login_inp: temp_login,
        login_bool: false,
        login_class: "form-control form-false",
      });
    }
  }

  handleChangePassword(event) {
    event.preventDefault();
    let temp_pass = event.target.value;
    this.setState({ password_inp: event.target.value });
    if (temp_pass !== "" && temp_pass.length >= 8) {
      this.setState({
        password_inp: temp_pass,
        password_bool: true,
        password_class: "input-group-custom form-true",
      });
    } else {
      this.setState({
        password_inp: temp_pass,
        password_bool: false,
        password_class: "input-group-custom form-false",
      });
    }
  }

  show_hide_password() {
    var input = document.getElementById("password");
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      this.setState({ hide_or_show_pass: false });
    } else {
      input.setAttribute("type", "password");
      this.setState({ hide_or_show_pass: true });
    }
    return false;
  }

  render() {
    ReactSession.setStoreType("sessionStorage");
    ReactSession.set("admin_token", "");

    return (
      <div className="main-container">
        {/* <BackButton/> */}
        <div className="adm-auth-container">
          <Row>
            <Col>
              <div className="title-left-text">Admin panel</div>

              <div className="description-left-text">
                Enter your login information
              </div>

              <Form onSubmit={this.handleSubmit}>
                <Form.Group
                  controlId="login"
                  onChange={this.handleChangeLogin.bind(this)}
                >
                  <FloatingLabel
                    controlId="login"
                    label="Enter login"
                    className="adm-auth-form-input"
                    value={this.state.login_inp}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter login"
                      className={this.state.login_class}
                      required
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  controlId="password"
                  className="authorization-form-input"
                  onChange={this.handleChangePassword.bind(this)}
                >
                  <InputGroup className={this.state.password_class}>
                    <FloatingLabel
                      controlId="password"
                      label="Enter password"
                      className="authorization-ff"
                      value={this.state.password_inp}
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        required
                        className="password-h-shw"
                      />
                    </FloatingLabel>
                    <button
                      className="hide-show-password"
                      onClick={this.show_hide_password.bind(this)}
                      type="button"
                    >
                      {this.state.hide_or_show_pass ? (
                        <BiShowAlt />
                      ) : (
                        <BiHide />
                      )}
                    </button>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {this.state.login_bool && this.state.password_bool ? (
                    !this.state.loading ? (
                      <button className="btn-filled" type="submit">
                        Login
                      </button>
                    ) : (
                      <button className="btn-filled" disabled>
                        <span>
                          Login{" "}
                          <span className="loadIcon">
                            <BiLoader />
                          </span>
                        </span>
                      </button>
                    )
                  ) : (
                    <button className="btn-outlined-dark" disabled>
                      Login
                    </button>
                  )}

                  {this.state.statusAuth !== "" ? (
                    <div className={this.state.text_class}>
                      {this.state.statusAuth}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
