import React from "react";

const StatusMessage = (props) => {
  return props.message !== "" ? (
    <p className={`text-little ${props.className}`}>{props.message}</p>
  ) : (
    <></>
  );
};

export default StatusMessage;
