import React from "react";

const Footer = () => {
  return (
    <div className="landing-footer">
      <div className="footer-row">
        <p className="text-little">© 2022 ARDIX</p>
      </div>
    </div>
  );
};

export default Footer;
