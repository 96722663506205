import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Card from "../../resources/img/card.png";

const Info = () => {
  const { t } = useTranslation();

  return (
    <div className="info-row">
      <div className="info-column info-text">
        <p className="text-little">{t("Landing.Contact.Text.Article1")}</p>
      </div>
      <div className="info-column info-img">
        <img id="card-img" src={Card} alt="card" />
      </div>
    </div>
  );
};

export default withTranslation()(Info);
