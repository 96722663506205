import React from "react";
import "../App.css";
import { Row, Col } from "react-bootstrap";
import { SiNfc } from "react-icons/si";
import { RiAdminLine, RiFileEditLine } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { PiClockCountdownFill } from "react-icons/pi";
import { ReactSession } from "react-client-session";

function ActionsAdm() {
  const routeToAddAdmin = (event) => {
    event.preventDefault();
    window.location.assign(`/AdminPanel/Add`);
  };

  const routeToWriteCard = (event) => {
    event.preventDefault();
    window.location.assign(`/AdminPanel/WriteCard`);
  };

  const routeToWriteGoodsAuthenticity = (event) => {
    event.preventDefault();
    window.location.assign(`/AdminPanel/WriteGoodsAuth`);
  };

  const routeToResetPassword = (event) => {
    event.preventDefault();
    window.location.assign(`/AdminPanel/ResetPassword`);
  };

  const logoutAdm = (event) => {
    event.preventDefault();
    ReactSession.setStoreType("sessionStorage");
    ReactSession.remove("admin_token");
    window.location.assign(`/AdminPanel/Authorization`);
  };

  function isLogin() {
    ReactSession.setStoreType("sessionStorage");

    if (
      ReactSession.get("admin_token") === undefined ||
      ReactSession.get("admin_token") === ""
    ) {
      window.location.assign(`/AdminPanel/Authorization`);
    } else {
      return true;
    }
  }

  return (
    <div className="main-container">
      {isLogin() ? (
        <>
          {/* <BackButton/> */}
          <div className="adm-actions-container">
            <Row>
              <Col>
                <div className="title-left-text-md3">Actions</div>

                <button
                  className="btn-filled margin-more"
                  onClick={routeToWriteCard}
                >
                  <div>
                    {" "}
                    <span className="icon-adm-panel-btn">
                      <SiNfc />
                    </span>{" "}
                    <span className="text-adm-panel-btn">Add a card</span>
                  </div>
                </button>

                <button
                  className="btn-filled margin-more"
                  type="button"
                  onClick={routeToWriteGoodsAuthenticity}
                >
                  <div>
                    {" "}
                    <span
                      className="icon-adm-panel-btn"
                      style={{ fontSize: 26 }}
                    >
                      <PiClockCountdownFill />
                    </span>{" "}
                    <span className="text-adm-panel-btn">
                      Add goods authenticity
                    </span>
                  </div>
                </button>

                <button
                  className="btn-filled margin-more"
                  onClick={routeToAddAdmin}
                >
                  <div>
                    {" "}
                    <span className="icon-adm-panel-btn">
                      <RiAdminLine />
                    </span>{" "}
                    <span className="text-adm-panel-btn">
                      Add administrator
                    </span>
                  </div>
                </button>
                <button
                  className="btn-filled margin-more"
                  onClick={routeToResetPassword}
                >
                  <div>
                    {" "}
                    <span className="icon-adm-panel-btn">
                      <RiFileEditLine />
                    </span>{" "}
                    <span className="text-adm-panel-btn">
                      Reset user password
                    </span>
                  </div>
                </button>
                <button className="btn-filled margin-more" onClick={logoutAdm}>
                  <div>
                    {" "}
                    <span className="icon-adm-panel-btn">
                      <MdLogout />
                    </span>{" "}
                    <span className="text-adm-panel-btn">Logout</span>
                  </div>
                </button>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ActionsAdm;
