import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel, InputGroup } from "react-bootstrap";
import { BiLoader, BiShowAlt, BiHide } from "react-icons/bi";
import { ReactSession } from "react-client-session";
import Config from "../config.js";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAuth: "",
      loading: false,
      text_class: "adm-status-auth",
      button_class: "btn-outlined-dark",

      password_inp: "",
      password_bool: false,
      password_class: "input-group-custom",
      hide_or_show_pass: true,

      repeat_password_inp: "",
      repeat_password_bool: false,
      repeat_password_class: "input-group-custom",
      hide_or_show_repeat_pass: true,

      valid_form: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  routeToCardPage(temp_password) {
    let uid = ReactSession.get("uid");

    fetch(`${Config.BACKEND_IP}/api/auth`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: uid,
        password: temp_password,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.error !== "Failed Auth" && result.token !== "") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: `Password created successfully.`,
            });
            console.log(`(Redirect after registration) Login succsessfully.`);
            ReactSession.set("user_token", result.token);
            ReactSession.set("welcome", true);
            window.location.assign(`/c/${uid}`);
          } else if (result.error === "Failed Auth") {
            this.setState({
              loading: false,
            });
            console.log(
              `(Redirect after registration) Wrong login or password.`
            );
            window.location.assign(`/c/${uid}`);
          } else {
            this.setState({
              loading: false,
            });
            console.log(
              `(Redirect after registration) Unexpected error: ${result}.`
            );
            window.location.assign(`/c/${uid}`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
          });
          console.log(
            `(Redirect after registration) Unexpected error: ${error}.`
          );
          window.location.assign(`/c/${uid}`);
        }
      );
  }

  handleSubmit(event) {
    this.setState({ loading: true, statusAuth: "" });
    let uid = ReactSession.get("uid");
    let temp_password = event.target.password.value;

    event.preventDefault();
    if (this.validationForm()) {
      fetch(`${Config.BACKEND_IP}/api/user/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: uid,
          password: event.target.password.value,
        }),
      })
        .then((response) => response.json())
        .then(
          (result) => {
            if (result.status === "Ok") {
              console.log(`Пароль создан успешно ${result.message}.`);
              this.routeToCardPage(temp_password);
            } else if (result.status === "Failed") {
              this.setState({
                loading: false,
                text_class: "adm-status-auth",
                statusAuth: `Error during registration.`,
              });
              console.log(`Error during registration: ${result.message}.`);
            } else {
              this.setState({
                loading: false,
                text_class: "adm-status-auth",
                statusAuth: `Unknown error during registration.`,
              });
              console.log(
                `Unknown error during registration: ${result.message}.`
              );
            }
          },
          (error) => {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Unexpected error.`,
            });
            console.log(`Unexpected error: ${error}.`);
          }
        );
    } else {
      this.setState({
        loading: false,
        text_class: "adm-status-auth",
        statusAuth: `Error: Passwords do not match.`,
        repeat_password_class: "input-group-custom form-false",
      });
      console.log(`Error: different password.`);
    }
  }

  validationForm() {
    if (this.state.repeat_password_bool && this.state.password_bool) {
      if (this.state.repeat_password_inp === this.state.password_inp) {
        return true;
      } else {
        return false;
      }
    }
  }

  handleChangeRepeatPassword(event) {
    event.preventDefault();
    this.setState({ repeat_password_inp: event.target.value });
    if (event.target.value !== "") {
      if (event.target.value.length >= 8) {
        this.setState({
          repeat_password_bool: true,
          repeat_password_class: "input-group-custom form-true",
        });
      } else {
        this.setState({
          repeat_password_bool: false,
          repeat_password_class: "input-group-custom form-false",
        });
      }
    } else {
      this.setState({
        repeat_password_bool: false,
        repeat_password_class: "input-group-custom",
        statusAuth: ``,
      });
    }
  }

  handleChangePassword(event) {
    event.preventDefault();
    this.setState({ password_inp: event.target.value });
    if (event.target.value !== "") {
      if (event.target.value.length >= 8) {
        if (
          event.target.value.match(/[0-9]+/) &&
          event.target.value.match(/[A-Za-z]+/)
        ) {
          this.setState({
            password_bool: true,
            statusAuth: ``,
            password_class: "input-group-custom form-true",
          });
        } else {
          this.setState({
            password_bool: false,
            text_class: "adm-status-auth",
            statusAuth: `The password must include at one number and letter.`,
            password_class: "input-group-custom form-false",
          });
        }
      } else {
        this.setState({
          password_bool: false,
          text_class: "adm-status-auth",
          statusAuth: `Minimum password length: 8 characters.`,
          password_class: "input-group-custom form-false",
        });
      }
    } else {
      this.setState({
        password_bool: false,
        statusAuth: ``,
        password_class: "input-group-custom",
      });
    }
  }

  show_hide_password() {
    var input = document.getElementById("password");
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      this.setState({ hide_or_show_pass: false });
    } else {
      input.setAttribute("type", "password");
      this.setState({ hide_or_show_pass: true });
    }
    return false;
  }

  show_hide_repeat_password() {
    var input = document.getElementById("repeat_password");
    if (input.getAttribute("type") === "password") {
      input.setAttribute("type", "text");
      this.setState({ hide_or_show_repeat_pass: false });
    } else {
      input.setAttribute("type", "password");
      this.setState({ hide_or_show_repeat_pass: true });
    }
    return false;
  }

  render() {
    ReactSession.setStoreType("sessionStorage");
    return (
      <div className="main-container">
        <div className="registration-container">
          <Row>
            <Col>
              <div className="title-left-text">Registration</div>

              <div className="description-left-text">
                To create a business card, begin with a password that will
                confirm your ownership to edit your vCard later.
              </div>

              <div className="description-left-text">
                Remember or store your password carefully!
              </div>

              <Form onSubmit={this.handleSubmit} autoComplete="off">
                <Form.Group
                  controlId="password"
                  className="authorization-form-input"
                  onChange={this.handleChangePassword.bind(this)}
                >
                  <InputGroup className={this.state.password_class}>
                    <FloatingLabel
                      controlId="password"
                      label="Enter password"
                      className="authorization-ff"
                      value={this.state.password_inp}
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        required
                        className="password-h-shw"
                      />
                    </FloatingLabel>
                    <button
                      className="hide-show-password"
                      onClick={this.show_hide_password.bind(this)}
                      type="button"
                      tabIndex={+2}
                    >
                      {this.state.hide_or_show_pass ? (
                        <BiShowAlt />
                      ) : (
                        <BiHide />
                      )}
                    </button>
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  controlId="repeat_password"
                  className="authorization-form-input"
                  onChange={this.handleChangeRepeatPassword.bind(this)}
                >
                  <InputGroup className={this.state.repeat_password_class}>
                    <FloatingLabel
                      controlId="repeat_password"
                      label="Repeat password"
                      className="authorization-ff"
                      value={this.state.repeat_password_inp}
                    >
                      <Form.Control
                        type="password"
                        placeholder="Repeat password"
                        required
                        className="password-h-shw"
                      />
                    </FloatingLabel>
                    <button
                      className="hide-show-password"
                      onClick={this.show_hide_repeat_password.bind(this)}
                      type="button"
                      tabIndex={-1}
                    >
                      {this.state.hide_or_show_repeat_pass ? (
                        <BiShowAlt />
                      ) : (
                        <BiHide />
                      )}
                    </button>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  {this.state.repeat_password_bool &&
                  this.state.password_bool ? (
                    !this.state.loading ? (
                      <button className="btn-filled" type="submit">
                        Submit
                      </button>
                    ) : (
                      <button className="btn-filled" disabled>
                        <span>
                          Submit{" "}
                          <span className="loadIcon">
                            <BiLoader />
                          </span>
                        </span>
                      </button>
                    )
                  ) : (
                    <button className="btn-outlined-dark" disabled>
                      Submit
                    </button>
                  )}

                  {this.state.statusAuth !== "" ? (
                    <div className={this.state.text_class}>
                      {this.state.statusAuth}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
