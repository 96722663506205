import React, { Component } from "react";
import { Modal, Form, FloatingLabel } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { FaPlus } from "react-icons/fa";

//модальное окно для добавление нового поля в визитку
//в состоянии только одно поле, посколько одновременно мы добавляем только одно
export default class AddFieldModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: {
        type: "",
        data: "",
        class: "",
        status: true,
      },
      isSocial: false,
      key: "TEL",
      type: "TEL",
      status: ``,
      text_class: "adm-status-auth",
      //какие типы полей мы можем добавить, получаем из login-field
      //некоторые поля уникальны и могут быть добавлены только один раз
      options: this.props.options,
      label: "Value",
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    //плейсхолдеры для каждого вида полей
    this.placeHolder = {
      PATRONYMICPATRONYMIC: "Patronymic",
      ORGORG: "Name of company",
      TITLETITLE: "Position",
      NOTENOTE: "Some text...",
      TELTEL: "+380123456789",
      EMAILEMAIL: "name@gmail.com",
      ADRADR: ["Postal code", "Country", "City", "Street", "Home number"],
      URLURL: "https://company.site",
      SOCIALFACEBOOK: "username",
      SOCIALLINKEDIN: "username",
      SOCIALINSTAGRAM: "username",
      SOCIALTELEGRAM: "username (without @)",
      SOCIALYOUTUBE: "https://youtube.com/channel/id",
      SOCIALVIBER: "380123456789",
      SOCIALWHATSAPP: "380123456789",
      SOCIALTWITTER: "username",
      SOCIALTIKTOK: "username (without @)",
    };

    //переменные для адаптивного размера поля Заметка
    this.note_params = {
      maxRows: 7,
      minRows: 1,
      count_symbols: 200,
    };
    this.rowHeight = 0;
  }

  //функция нужна для того, чтобы постоянно обновлять список доступных типов полей
  componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  //отправка данных в login-fields
  handleSubmit(event) {
    event.preventDefault();
    let value = "";
    let key = event.target.newKey.value;
    let type =
      key === "ADR"
        ? "ADR"
        : key === "TEL"
        ? "TEL"
        : key === "EMAIL"
        ? "EMAIL"
        : key === "URL"
        ? "URL"
        : key === "ORG"
        ? "ORG"
        : key === "TITLE"
        ? "TITLE"
        : key === "BDAY"
        ? "BDAY"
        : key === "NOTE"
        ? "NOTE"
        : key === "PATRONYMIC"
        ? "PATRONYMIC"
        : event.target.newType.value;

    //если поле - адрес, надо распарсить обьект и превратить его в строку, где каждые элемент через точку с запятой
    if (key === "ADR") {
      value = {
        address:
          event.target.address.value !== null &&
          event.target.address.value !== undefined
            ? event.target.address.value.trim()
            : "",
        city:
          event.target.city.value !== null &&
          event.target.city.value !== undefined
            ? event.target.city.value.trim()
            : "",
        region:
          event.target.region.value !== null &&
          event.target.region.value !== undefined
            ? event.target.region.value.trim()
            : "",
        postal_code:
          event.target.postal_code.value !== null &&
          event.target.postal_code.value !== undefined
            ? event.target.postal_code.value.trim()
            : "",
        country:
          event.target.country.value !== null &&
          event.target.country.value !== undefined
            ? event.target.country.value.trim()
            : "",
      };
    } else {
      value =
        event.target.newValue.value === undefined
          ? ""
          : event.target.newValue.value;
    }

    //вызываем прокинутую вниз функцию с login-fields
    this.props.addFieldFromModal(type, value, key);
    //обнуляем состояние
    this.setState({
      key: "TEL",
      type: "TEL",
      isSocial: false,
      field: {
        type: "",
        data: "",
        class: "",
        status: true,
      },
    });
    //закрываем модальное окно
    this.props.onHide();
  }

  //валидация почты
  handleChangeEmail(event) {
    let email_temp = {
      type: this.state.type,
      data: event.target.value,
      class: "",
      status: "",
    };

    //если не пустое
    if (email_temp.data !== "") {
      // регулярка на правильность почты
      if (
        email_temp.data.match(
          /^([A-za-z0-9_-]+\.)*[A-za-z0-9_-]+@[A-za-z0-9_-]+(\.[A-za-z0-9_-]+)*\.[A-za-z]{1,100}$/
        )
      ) {
        email_temp.class = ``;
        email_temp.status = true;
        this.setState({
          field: email_temp,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        email_temp.class = `form-control form-false`;
        email_temp.status = false;
        this.setState({
          field: email_temp,
          status: `Please enter a valid email.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      email_temp.class = `form-control`;
      email_temp.status = true;
      this.setState({
        field: email_temp,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  // валидация номера
  handleChangePhone(event) {
    let phone_temp = {
      type: this.state.field.type,
      data: event.target.value.replace(/[\s\-())]/g, ""),
      class: "",
      status: "",
    };

    if (phone_temp.data !== "") {
      //регулярка на правильность номера (допускает все страны; +; -; (); стационарные)
      if (
        phone_temp.data.match(
          /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){3,14}(\s*)?$/
        )
      ) {
        phone_temp.class = ``;
        phone_temp.status = true;
        this.setState({
          field: phone_temp,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        phone_temp.class = `form-control form-false`;
        phone_temp.status = false;
        this.setState({
          field: phone_temp,
          status: `Please enter a valid mobile number.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      phone_temp.class = `form-control`;
      phone_temp.status = true;
      this.setState({
        field: phone_temp,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  // валидация url
  handleChangeURL(event) {
    let url_temp = {
      type: this.state.field.type,
      data: event.target.value,
      class: "",
      status: "",
    };

    if (url_temp.data !== "") {
      //регулярка на правильность url, допускает доменные имена и ip
      if (this.isValidUrl(event.target.value)) {
        url_temp.class = ``;
        url_temp.status = true;
        this.setState({
          field: url_temp,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        url_temp.class = `form-control form-false`;
        url_temp.status = false;
        this.setState({
          field: url_temp,
          status: `Please enter a valid URL.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      url_temp.class = `form-control`;
      url_temp.status = true;
      this.setState({
        field: url_temp,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  isValidUrl(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  }

  // валидация Заметки (изменение размера в зависимости от содержимого)
  handleChangeNote = (event) => {
    let note_temp = {
      type: "NOTE",
      data: event.target.value,
      class: "",
      status: "",
    };

    const currentRows = Math.floor((this.rowHeight - 14.4) / 24);

    if (note_temp.data !== "") {
      if (
        note_temp.data.length <= 200 &&
        currentRows <= this.note_params.maxRows
      ) {
        note_temp.class = `form-control`;
        note_temp.status = "true";
        this.setState({
          field: note_temp,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        note_temp.class = `form-control form-false`;
        note_temp.status = undefined;
        this.setState({
          field: note_temp,
          status: `Maximum note length: ${this.note_params.count_symbols} characters or ${this.note_params.maxRows} rows.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      note_temp.class = `form-control`;
      note_temp.status = "true";
      this.setState({
        field: note_temp,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  };

  // отслеживать изменение типа (ключа), чтобы подбирать по него подтипы
  handleChangeKey(event) {
    let tmp = event.target.value;
    this.setState({
      key: event.target.value,
      type:
        event.target.value === "TEL"
          ? "TEL"
          : event.target.value === "EMAIL"
          ? "EMAIL"
          : event.target.value === "NOTE"
          ? "NOTE"
          : event.target.value === "URL"
          ? "URL"
          : event.target.value === "ADR"
          ? "ADR"
          : event.target.value === "ORG"
          ? "ORG"
          : event.target.value === "TITLE"
          ? "TITLE"
          : event.target.value === "BDAY"
          ? "BDAY"
          : event.target.value === "PATRONYMIC"
          ? "PATRONYMIC"
          : "NULL",
      isSocial: event.target.value === "SOCIAL" ? true : false,
      field: {
        type: "",
        data:
          event.target.value === "ADR"
            ? {
                address: "",
                region: "",
                city: "",
                postal_code: "",
                country: "",
              }
            : "",
        class: "",
        status: true,
      },
      label: tmp === "WORK" || tmp === "URL" ? "Value" : "",
    });
    this.setState({});
  }

  // отслеживание изменений в подтипе и задание плейсхолдера
  handleChangeType(event) {
    let tmp = event.target.value;
    this.setState({
      type: event.target.value,
      label: this.placeHolder[this.state.key + tmp],
    });
    this.setState({});
  }

  // отслеживания изменений в поле значение и его хранение в состоянии
  handleChangeValue(event) {
    let newField = this.state.field;
    newField.data = event.target.value;
    this.setState({
      field: newField,
    });
  }

  // отслеживание изменения адреса
  handleChangeAddress(event, subtype) {
    let temp_field = this.state.field;

    temp_field.data[subtype] = event.target.value;

    this.setState({
      field: temp_field,
    });
  }

  // компонент с выбором типа
  FieldType() {
    return (
      <>
        <Form.Group
          controlId="newKey"
          onChange={(event) => this.handleChangeKey(event)}
        >
          <FloatingLabel
            controlId="newKey"
            label="Type"
            className="addfield card-form-input"
            value={this.state.key}
          >
            <Form.Control
              className="addfield"
              as="select"
              custom
              defaultValue={this.state.key}
            >
              {
                // этих полей может быть бесконечное количество
              }
              <option value="TEL" selected>
                Phone
              </option>
              <option value="EMAIL">Email</option>
              <option value="URL">Site</option>
              <option value="ADR">Address</option>
              {
                // остальные берем с допустимого списка типов
              }
              {this.state.options[0].map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
        {
          // вызываем следующий компонент
        }
        {this.FieldSubType(this.state.key)}
      </>
    );
  }

  // компонент подтипов
  FieldSubType(type) {
    return (
      <>
        {
          // если это социальная сеть - выводим подтипы, иначе пропускаем и рендерим следующий компонент
        }
        {this.state.key === "SOCIAL" ? (
          <Form.Group
            controlId="newType"
            onChange={(event) => this.handleChangeType(event)}
          >
            <FloatingLabel
              controlId="newType"
              label="Subtype"
              className="addfield card-form-input"
              value={this.state.type}
            >
              <Form.Control
                className="addfield"
                as="select"
                defaultValue={this.state.type}
              >
                {
                  // по умолчанию, чтобы заставить пользователя выбрать
                }
                <option value="NULL" disabled hidden selected>
                  Select
                </option>
                {
                  // перебор и вывод всех допустимых подтипов для определенного типа
                }
                {this.state.options[1].map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Form.Control>
            </FloatingLabel>
          </Form.Group>
        ) : null}
        {this.FieldValue(this.state.key, this.state.type)}
      </>
    );
  }

  // компонент с вводом адреса (вводим сразу много строк по пунктам)
  Address() {
    return (
      <>
        <Form.Group
          controlId="country"
          onChange={(event) => this.handleChangeAddress(event, "country")}
        >
          <FloatingLabel
            controlId="country"
            className="card-form-input"
            value={this.state.field.data.country}
            label="Country"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.country}
              placeholder="Country"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="region"
          onChange={(event) => this.handleChangeAddress(event, "region")}
        >
          <FloatingLabel
            controlId="region"
            className="card-form-input"
            label="Region"
            value={this.state.field.data.region}
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.region}
              placeholder="Region"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="city"
          onChange={(event) => this.handleChangeAddress(event, "city")}
        >
          <FloatingLabel
            controlId="city"
            className="card-form-input"
            label="City"
            value={this.state.field.data.city}
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.city}
              placeholder="City"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="postal_code"
          onChange={(event) => this.handleChangeAddress(event, "postal_code")}
        >
          <FloatingLabel
            controlId="postal_code"
            label="Postal code"
            className="card-form-input"
            value={this.state.field.data.postal_code}
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.postal_code}
              placeholder="Postal code"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="address"
          onChange={(event) => this.handleChangeAddress(event, "address")}
        >
          <FloatingLabel
            controlId="address"
            label="Street and house"
            className="card-form-input"
            value={this.state.field.data.address}
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.address}
              placeholder="Street and house"
            />
          </FloatingLabel>
        </Form.Group>
      </>
    );
  }

  // компонент ввода значения (если поле - адрес, то рендерим соответствующий компонент)
  FieldValue(type, subtype) {
    return type === "ADR" ? (
      this.Address()
    ) : (
      <Form.Group
        controlId="newValue"
        onChange={
          // выбираем функцию для обработки изменений в поле
          type === "TEL"
            ? (event) => this.handleChangePhone(event)
            : type === "EMAIL"
            ? (event) => this.handleChangeEmail(event)
            : type === "NOTE"
            ? (event) => this.handleChangeNote(event)
            : type === "URL"
            ? (event) => this.handleChangeURL(event)
            : (event) => this.handleChangeValue(event)
        }
      >
        {
          //если это заметка, то рендерим тип textarea
          //если дата рождения, то тип date
          // иначе только текст
          type === "NOTE" ? (
            <TextareaAutosize
              id="newValue"
              onHeightChange={(rowHeight) => {
                this.rowHeight = rowHeight;
              }}
              minRows={this.note_params.minRows}
              maxRows={Infinity}
              label={"Note"}
              placeholder={this.placeHolder[type + subtype]}
              className={
                this.state.field.class !== ""
                  ? this.state.field.class
                  : "form-control"
              }
              defaultValue={this.state.field.data}
            />
          ) : type === "BDAY" ? (
            <Form.Control
              type="date"
              placeholder={this.placeHolder[type + subtype]}
              value={this.state.field.data}
              label={"Value"}
              className={`card-form-input addfield ${this.state.field.class}`}
            />
          ) : (
            <Form.Control
              type="text"
              placeholder={this.placeHolder[type + subtype]}
              value={this.state.field.data}
              label={"Value"}
              className={`card-form-input addfield ${this.state.field.class}`}
            />
          )
        }
      </Form.Group>
    );
  }

  render() {
    return (
      <div className="container modal">
        <Modal
          {...this.props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add field
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={(event) => this.handleSubmit(event)}
              autoComplete="off"
            >
              {
                // вызов компонента, который рекурсивным спуском строит нужную структуру формы
              }
              {this.FieldType()}

              <Form.Group>
                {
                  // активная или не активная кнопка
                  // если статус поля true (поле прошло валидацию), тип и подтип не нулевые - активна
                }

                {this.state.field.status &&
                this.state.key !== "NULL" &&
                this.state.type !== "NULL" ? (
                  <button
                    className="btn-outlined-color low-margin-top 1"
                    type="submit"
                  >
                    <span>
                      <span className="add-field-plus-btn">
                        <FaPlus />
                      </span>
                      Add
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn-outlined-dark low-margin-top 3"
                    type="button"
                    disabled
                  >
                    <span>
                      <span className="add-field-plus-btn">
                        <FaPlus />
                      </span>
                      Add
                    </span>
                  </button>
                )}
              </Form.Group>
            </Form>
            {
              // вывод ошибок, оповещений и другой информации
              //если переменная с содержимим пустая, то ничего не выводим, иначе содержимое
              // цвет текста зависит от переменной text_class
            }
            {this.state.status !== "" ? (
              <div className={this.state.text_class}>{this.state.status}</div>
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
