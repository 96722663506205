import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

import MockUp from "../../resources/img/MockUp.svg";
import Shadow from "../../resources/img/Shadow.svg";

const MainScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-mainscreen">
      <div className="mainscreen-row">
        <div className="mainscreen-column mainscreen-column-text">
          <div className="mainscreen-text">
            <p
              id="text-start"
              className="text-little landing-text-mw landing-text-padding "
            >
              {t("Landing.Main.Text.Title1")}
            </p>

            <h1 id="text-start" className="text-big landing-text-padding">
              {t("Landing.Main.Text.Title2")}
            </h1>

            <h1
              id="text-center"
              className="text-big-main mainscreen-stroke landing-text-padding"
            >
              {t("Landing.Main.Text.Title3")}
            </h1>

            <h1
              id="text-end"
              className="text-big  landing-text-mw landing-text-padding"
            >
              {t("Landing.Main.Text.Title4")}
            </h1>
          </div>
        </div>
        <div id="mockup-column" className="mainscreen-column">
          <div id="mockup-column" className="mainscreen-row">
            <div className="mockup-image">
              <img
                className="mockup"
                src={MockUp}
                alt="ardix vcard interface"
              ></img>
            </div>
            <div className="shadow-div">
              <img id="shadow" src={Shadow} alt="shadow"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(MainScreen);
