import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

const AboutColumn = (props) => {
  const { t } = useTranslation();

  return (
    <div className="about-column-outer">
      <div className="about-row-inside">
        <div className="about-column-inside about-col-icon">
          <img id="about-icon" src={props.icon} alt="user icon"></img>
        </div>
        <div className="about-column-inside about-col-title">
          <h2 className="text-middle text-start">{t(props.title)}</h2>
        </div>
      </div>
      <div className="about-row-inside">
        <div className="about-column-inside about-col-text">
          <ul style={{ listStylePosition: "inside", margin: 0, padding: 0 }}>
            <li className="list-item text-little" style={{ marginBottom: 0 }}>
              {t(props.p1)}
            </li>
            <li className="list-item text-little">{t(props.p2)}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AboutColumn);
