import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import cube from "../../resources/img/cube.svg";

const Cube = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-cube">
      <div className="cube-row">
        <div className="cube-column cube-column-text">
          <div className="text-big">{t("Landing.Cube.Text.Title1")}</div>
        </div>
        <div className="cube-column cube-img">
          <img src={cube} id="cube" alt="cube-secure" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Cube);
