import React, { Component } from "react";
import "../App.css";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let img_style = {
      backgroundImage: "url(https://i.ibb.co/QvkvsGb/pngaaa-com-4333947.png)",
    };

    return (
      <div className="main-container">
        <div className="authorization-container">
          <div className="title-left-text">
            We're sorry, but it looks like you're lost :c
          </div>

          <div className="description-left-text">This page does not exist.</div>

          <div className="not-found-page-img" style={img_style}></div>
        </div>
      </div>
    );
  }
}
