const Config = {
  BACKEND_IP: "https://service.idcard.ua",
  SHORTER_IP: "",
  FRONTEND_IP: "https://nfc.ardix.ua",
  FIELDS_NAMES: {
    NAME: "Name",
    SURNAME: "Surname",
    PATRONYMIC: "Patronymic",

    TEL: "Phone",
    EMAIL: "Email",

    ORG: "Company name",
    TITLE: "Position",
    ADR: "Address",

    BDAY: "Birthday",

    URL: "Site",
    SOCIAL: "",

    NOTE: "Note",

    status: null,
    photoId: null,
    id: null,
    firstLogin: null,
  },
};

export default Config;
