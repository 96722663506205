import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { BiLoader } from "react-icons/bi";
import Config from "../config.js";
import { ReactSession } from "react-client-session";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusAuth: null,
      loading: false,
      text_class: "adm-status-auth",
      uid: "",
      uid_bool: false,
      uid_class: "form-control",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.setState({ loading: true });
    let token = `Bearer ${ReactSession.get("admin_token")}`;
    event.preventDefault();
    fetch(
      `${Config.BACKEND_IP}/api/passwordForgotten/${event.target.uid.value}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.message === "Ok") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: `The password was reset successfully.`,
            });
            console.log("Пароль успешно сброшен.");
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Unexpected error while reset password.`,
            });
            console.log(`Ошибка при сбросе пароля ${result.message}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `Unexpected error.`,
          });
          console.log(`Непредвиденная ошибка: ${error}.`);
        }
      );
  }

  handleChangeUID(event) {
    event.preventDefault();
    let temp_uid = event.target.value;
    if (event.target.value !== "") {
      this.setState({
        uid: temp_uid,
        uid_bool: true,
        statusAuth: ``,
        uid_class: "form-control form-true",
      });
    } else {
      this.setState({
        uid: temp_uid,
        uid_bool: false,
        statusAuth: ``,
        uid_class: "form-control",
      });
    }
  }

  logoutAdm() {
    ReactSession.setStoreType("sessionStorage");

    if (
      ReactSession.get("admin_token") === undefined ||
      ReactSession.get("admin_token") === ""
    ) {
      window.location.assign(`/AdminPanel/Authorization`);
    }
  }

  render() {
    this.logoutAdm();
    ReactSession.setStoreType("sessionStorage");
    return (
      <div className="main-container">
        <div className="adm-add-container">
          <Row>
            <Col>
              <div className="title-left-text adm">Reset user password</div>

              <Form onSubmit={this.handleSubmit} autoComplete="off">
                <Form.Group
                  controlId="uid"
                  onChange={this.handleChangeUID.bind(this)}
                >
                  <FloatingLabel
                    controlId="uid"
                    label="Enter user id"
                    className="adm-add-form-input"
                    value={this.state.uid}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter user id"
                      className={this.state.uid_class}
                      required
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group>
                  {this.state.uid_bool ? (
                    !this.state.loading ? (
                      <button className="btn-filled" type="submit">
                        Reset
                      </button>
                    ) : (
                      <button
                        className="btn-filled"
                        style={{ cursor: "wait", pointerEvents: "none" }}
                        disabled
                      >
                        <span>
                          Reset{" "}
                          <span className="loadIcon">
                            <BiLoader />
                          </span>
                        </span>
                      </button>
                    )
                  ) : (
                    <button
                      className="btn-outlined-dark"
                      style={{ cursor: "no-drop" }}
                      disabled
                    >
                      Reset
                    </button>
                  )}

                  {this.state.statusAuth != null ? (
                    <div className={this.state.text_class}>
                      {this.state.statusAuth}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
