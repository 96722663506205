import React, { Component } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { ReactSession } from "react-client-session";
import { BiLoader } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import "../App.css";
import Config from "../config";
import AddFieldModal from "./add-field-modal";
import { ChangeFieldModal } from "./change-field-modal";

export default class ChangeableFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.fields,
      status: ``,
      text_class: "adm-status-auth",
      addFieldModalShow: false,
      changeFieldModalShow: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.loading = this.props.loading;
    this.note_params = {
      maxRows: 7,
      minRows: 1,
      count_symbols: 200,
    };
    this.rowHeight = 0;

    this.cropedImage = undefined;
  }

  routeBack(event) {
    event.preventDefault();
    ReactSession.set("user_token", "");
    window.location.reload();
  }

  getOptions() {
    let options_keys = [];
    let options_types = [];

    let isPatronymic = false;

    let isOrg = false;
    let isTitle = false;

    let isBday = false;

    let isNote = false;

    let isFacebook = false;
    let isInstagram = false;
    let isLinkedIn = false;
    let isTelegram = false;
    let isYoutube = false;
    let isViber = false;
    let isWhatsapp = false;
    let isTwitter = false;
    let isTiktok = false;

    this.state.fields.map((field) =>
      field.keyName === "PATRONYMIC"
        ? (isPatronymic = true)
        : field.keyName === "ORG"
        ? (isOrg = true)
        : field.keyName === "TITLE"
        ? (isTitle = true)
        : field.keyName === "BDAY"
        ? (isBday = true)
        : field.keyName === "NOTE"
        ? (isNote = true)
        : field.keyName === "SOCIAL"
        ? field.type === "FACEBOOK"
          ? (isFacebook = true)
          : field.type === "LINKEDIN"
          ? (isLinkedIn = true)
          : field.type === "INSTAGRAM"
          ? (isInstagram = true)
          : field.type === "TELEGRAM"
          ? (isTelegram = true)
          : field.type === "YOUTUBE"
          ? (isYoutube = true)
          : field.type === "VIBER"
          ? (isViber = true)
          : field.type === "WHATSAPP"
          ? (isWhatsapp = true)
          : field.type === "TWITTER"
          ? (isTwitter = true)
          : field.type === "TIKTOK"
          ? (isTiktok = true)
          : null
        : null
    );

    if (!isPatronymic) {
      options_keys.push({
        value: "PATRONYMIC",
        label: "Patronymic",
      });
    }
    if (!isOrg) {
      options_keys.push({
        value: "ORG",
        label: "Company name",
      });
    }
    if (!isTitle) {
      options_keys.push({
        value: "TITLE",
        label: "Position",
      });
    }
    if (!isBday) {
      options_keys.push({
        value: "BDAY",
        label: "Birthday",
      });
    }
    if (!isNote) {
      options_keys.push({
        value: "NOTE",
        label: "Note",
      });
    }
    if (
      !isFacebook ||
      !isInstagram ||
      !isLinkedIn ||
      !isTelegram ||
      !isYoutube ||
      !isViber ||
      !isWhatsapp ||
      !isTwitter ||
      !isTiktok
    ) {
      options_keys.push({
        value: "SOCIAL",
        label: "Social network",
      });
    }

    if (!isFacebook) {
      options_types.push({
        value: "FACEBOOK",
        label: "Facebook",
      });
    }
    if (!isInstagram) {
      options_types.push({
        value: "INSTAGRAM",
        label: "Instagram",
      });
    }
    if (!isLinkedIn) {
      options_types.push({
        value: "LINKEDIN",
        label: "LinkedIn",
      });
    }
    if (!isTelegram) {
      options_types.push({
        value: "TELEGRAM",
        label: "Telegram",
      });
    }
    if (!isYoutube) {
      options_types.push({
        value: "YOUTUBE",
        label: "Youtube",
      });
    }
    if (!isViber) {
      options_types.push({
        value: "VIBER",
        label: "Viber",
      });
    }
    if (!isTwitter) {
      options_types.push({
        value: "TWITTER",
        label: "Twitter",
      });
    }
    if (!isWhatsapp) {
      options_types.push({
        value: "WHATSAPP",
        label: "Whatsapp",
      });
    }
    if (!isTiktok) {
      options_types.push({
        value: "TIKTOK",
        label: "TikTok",
      });
    }

    return [options_keys, options_types];
  }

  getToSendData() {
    let data = [];

    this.state.fields.map((field) =>
      field.data !== "" && field.data !== null && field.data !== undefined
        ? field.id !== undefined && field.id !== null
          ? data.push({
              id: field.id,
              type: field.type,
              keyName: field.keyName,
              data:
                field.keyName === "ADR"
                  ? this.getAdrStringFromObj(field.data, ";", ";")
                  : field.data,
            })
          : data.push({
              id: null,
              type: field.type,
              keyName: field.keyName,
              data:
                field.keyName === "ADR"
                  ? this.getAdrStringFromObj(field.data, ";", ";")
                  : field.data,
            })
        : field.id !== undefined && field.id !== null
        ? data.push({
            id: field.id,
            type: field.type,
            keyName: field.keyName,
            data: " ",
          })
        : null
    );
    return data;
  }

  blobToFile(theBlob, fileName, date) {
    return new File([theBlob], fileName, {
      lastModified: date,
      type: theBlob.type,
    });
  }

  uploadNewImage = async (img, token, uid, toSend) => {
    this.setState({ loading: true });

    let date = new Date().getTime();

    let file = this.blobToFile(img, `${uid}_${date}.png`, date);
    // this.saveFile(file);

    var data = new FormData();
    data.append("file", file);

    return fetch(`${Config.BACKEND_IP}/api/photo`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return;
        }
      })
      .then(
        (result) => {
          if (result.status !== "Failed") {
            this.props.liftingStatus("", "adm-status-auth");
            console.log("Фото загружено успешно.");
            this.props.setPhotoIdLift(result.id);
            return this.fetchPUTApiCard(token, uid, toSend);
          } else if (result.status === "Failed") {
            this.props.liftingStatus(`Error loading photo.`, "adm-status-auth");
            console.log(`Ошибка при загрузке фото: ${result.status}.`);
          } else {
            this.props.liftingStatus(
              `An unexpected error occurred while uploading a photo.`,
              "adm-status-auth"
            );
            console.log(`Непредвиденная ошибка при загрузке фото: ${result}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
          });
          this.props.liftingStatus(
            `An unexpected error occurred while uploading a photo.`,
            "adm-status-auth"
          );
          console.log(`Непредвиденная ошибка при загрузке фото: ${error}.`);
        }
      );
  };

  async fetchPUTApiCard(token, uid, toSend) {
    await fetch(`${Config.BACKEND_IP}/api/card/${uid}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        information: toSend,
        photoId: this.props.photoId,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.message === "Ok") {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
            });
            ReactSession.set("user_token", "");
            window.location.assign(`/c/${uid}`);
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
            });
            console.log(`Ошибка при редактировании: ${result.message}.`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
          });
          console.log(`Непредвиденная ошибка при редактировании: ${error}.`);
        }
      );
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true, status: "" });
    let uid = ReactSession.get("uid");
    let token = `Bearer ${this.props.token}`;

    let toSend = this.getToSendData();

    if (this.props.editor.current !== null) {
      await this.savedCropImage(token, uid, toSend);
    } else {
      await this.fetchPUTApiCard(token, uid, toSend);
    }
  }

  addFieldModalOpen() {
    this.setState({
      addFieldModalShow: true,
    });
  }

  changeFieldModalOpen() {
    this.setState({
      changeFieldModalShow: true,
    });
  }

  changeFieldModalClose() {
    this.setState({ changeFieldModalShow: false });
  }

  addFieldModalClose() {
    this.setState({ addFieldModalShow: false });
  }

  addFieldFromModal(type, value, key) {
    let new_field = {};
    if (key === "TEL" || key === "EMAIL" || key === "NOTE") {
      new_field = {
        type: type,
        keyName: key,
        data: value,
        class: "form-control",
        status: false,
      };
    } else {
      new_field = {
        type: type,
        keyName: key,
        data: value,
      };
    }

    let newFields = this.state.fields;
    newFields.push(new_field);
    this.setState({
      fields: newFields,
    });
  }

  changeFieldFromModal(value, number) {
    let newFields = this.state.fields;
    let newAddress = newFields[number];

    newAddress.data = value;

    newFields[number] = newAddress;
    this.setState({
      fields: newFields,
    });
  }

  handleChangeEmail(event, id_, type_, key_, class_, status_, number) {
    let email_temp = {
      id: id_,
      type: type_,
      keyName: key_,
      data: event.target.value,
      class: class_,
      status: status_,
    };

    let newFields = this.state.fields;

    if (email_temp.data !== "") {
      if (
        email_temp.data.match(
          /^([A-za-z0-9_-]+\.)*[A-za-z0-9_-]+@[A-za-z0-9_-]+(\.[A-za-z0-9_-]+)*\.[A-za-z]{1,100}$/
        )
      ) {
        email_temp.class = ``;
        email_temp.status = "true";
        newFields[number] = email_temp;
        this.setState({
          fields: newFields,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        email_temp.class = `form-control form-false`;
        email_temp.status = undefined;
        newFields[number] = email_temp;
        this.setState({
          fields: newFields,
          status: `Please enter a valid email.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      email_temp.class = `form-control`;
      email_temp.status = "true";
      newFields[number] = email_temp;
      this.setState({
        fields: newFields,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  handleChangePhone(event, id_, type_, key_, class_, status_, number) {
    let phone_temp = {
      id: id_,
      type: type_,
      keyName: key_,
      data: event.target.value.replace(/[\s\-())]/g, ""),
      class: class_,
      status: status_,
    };

    let newFields = this.state.fields;

    if (phone_temp.data !== "") {
      // .match(/^((\+?3)?8)?0\d{9}$/)
      if (
        phone_temp.data.match(
          /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){3,14}(\s*)?$/
        )
      ) {
        phone_temp.class = ``;
        phone_temp.status = "true";
        newFields[number] = phone_temp;
        this.setState({
          fields: newFields,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        phone_temp.class = `form-control form-false`;
        phone_temp.status = undefined;
        newFields[number] = phone_temp;
        this.setState({
          fields: newFields,
          status: `Please enter a valid mobile number.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      phone_temp.class = `form-control`;
      phone_temp.status = "true";
      newFields[number] = phone_temp;
      this.setState({
        fields: newFields,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  handleChangeField(event, id_, type_, key_, number) {
    let newFields = this.state.fields;

    let temp_field = {
      id: id_,
      type: type_,
      keyName: key_,
      data: event.target.value,
    };

    newFields[number] = temp_field;
    this.setState({
      fields: newFields,
    });
  }

  handleChangeNote = (event, id_, type_, key_, number, class_, status_) => {
    let note_temp = {
      id: id_,
      type: type_,
      keyName: key_,
      data: event.target.value,
      class: class_,
      status: status_,
    };

    let newFields = this.state.fields;

    const currentRows = Math.floor((this.rowHeight - 38) / 20);

    if (note_temp.data !== "") {
      if (
        note_temp.data.length <= 200 &&
        currentRows <= this.note_params.maxRows
      ) {
        note_temp.class = `form-control`;
        note_temp.status = "true";
        newFields[number] = note_temp;
        this.setState({
          fields: newFields,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        note_temp.class = `form-control form-false`;
        note_temp.status = undefined;
        newFields[number] = note_temp;
        this.setState({
          fields: newFields,
          status: `Maximum note length: ${this.note_params.count_symbols} characters or ${this.note_params.maxRows} rows.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      note_temp.class = `form-control`;
      note_temp.status = "true";
      newFields[number] = note_temp;
      this.setState({
        fields: newFields,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  };

  handleChangeURL(event, id_, type_, key_, class_, status_, number) {
    let url_temp = {
      id: id_,
      type: type_,
      keyName: key_,
      data: event.target.value.replace(/[\s\-())]/g, ""),
      class: class_,
      status: status_,
    };

    let newFields = this.state.fields;

    if (url_temp.data !== "") {
      if (this.isValidUrl(event.target.value)) {
        url_temp.class = ``;
        url_temp.status = "true";
        newFields[number] = url_temp;
        this.setState({
          fields: newFields,
          status: ``,
          text_class: "adm-status-auth",
        });
      } else {
        url_temp.class = `form-control form-false`;
        url_temp.status = undefined;
        newFields[number] = url_temp;
        this.setState({
          fields: newFields,
          status: `Please enter a valid URL.`,
          text_class: "adm-status-auth",
        });
      }
    } else {
      url_temp.class = `form-control`;
      url_temp.status = "true";
      newFields[number] = url_temp;
      this.setState({
        fields: newFields,
        status: ``,
        text_class: "adm-status-auth",
      });
    }
  }

  isValidUrl(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  }

  getFieldName(name_field) {
    if (Config.FIELDS_NAMES[name_field] !== null) {
      return Config.FIELDS_NAMES[name_field];
    } else {
      return null;
    }
  }

  getAdrStringFromObj(adr, separator1, separator2) {
    if (
      adr.address === "" &&
      adr.city === "" &&
      adr.region === "" &&
      adr.postal_code === "" &&
      adr.country === ""
    ) {
      return " ";
    } else {
      let str =
        adr.address !== null && adr.address !== undefined && adr.address !== ""
          ? adr.address + `${separator1}`
          : `${separator2}`;
      str +=
        adr.city !== null && adr.city !== undefined && adr.city !== ""
          ? adr.city + `${separator1}`
          : `${separator2}`;
      str +=
        adr.region !== null && adr.region !== undefined && adr.region !== ""
          ? adr.region + `${separator1}`
          : `${separator2}`;
      str +=
        adr.postal_code !== null &&
        adr.postal_code !== undefined &&
        adr.postal_code !== ""
          ? adr.postal_code + `${separator1}`
          : `${separator2}`;
      str +=
        adr.country !== null && adr.country !== undefined && adr.country !== ""
          ? adr.country
          : "";
      return str;
    }
  }

  Fields() {
    return this.state.fields.map((field, number) =>
      this.getFieldName(field.keyName) !== null && field.data !== null
        ? this.Field(field.keyName, number)
        : null
    );
  }

  changeActiveField(field, number) {
    this.setState({
      change_field: field,
      change_number: number,
      changeFieldModalShow: true,
    });
  }

  Field(name, number) {
    let field_name = this.getFieldName(name);

    let id_ = this.state.fields[number].id;
    let type_ = this.state.fields[number].type;
    let key_ = this.state.fields[number].keyName;
    let class_ = this.state.fields[number].class;
    let status_ = this.state.fields[number].status ? "true" : undefined;
    let change_field = this.state.fields[number];

    let label =
      key_ === "TEL" ||
      key_ === "EMAIL" ||
      key_ === "NOTE" ||
      key_ === "ADR" ||
      key_ === "URL" ||
      key_ === "BDAY" ||
      key_ === "ORG" ||
      key_ === "TITLE" ||
      key_ === "PATRONYMIC"
        ? field_name
        : key_ === "SOCIAL"
        ? type_ === "FACEBOOK"
          ? field_name + "Facebook"
          : type_ === "LINKEDIN"
          ? field_name + "LinkedIn"
          : type_ === "INSTAGRAM"
          ? field_name + "Instagram"
          : type_ === "TELEGRAM"
          ? field_name + "Telegram"
          : type_ === "YOUTUBE"
          ? field_name + "Youtube"
          : type_ === "VIBER"
          ? field_name + "Viber"
          : type_ === "WHATSAPP"
          ? field_name + "Whatsapp"
          : type_ === "TWITTER"
          ? field_name + "Twitter"
          : type_ === "TIKTOK"
          ? field_name + "TikTok"
          : field_name
        : field_name;

    return (
      <div key={`outer-form-group${number}`}>
        {name === "NOTE" ? (
          this.Note("NOTE", number)
        ) : name === "BDAY" ? (
          <Form.Group
            controlId={name}
            key={`form-group${number}`}
            onChange={(event) =>
              this.handleChangeField(event, id_, type_, key_, number)
            }
          >
            <FloatingLabel
              controlId={name}
              label={label}
              className="card-form-input"
              value={this.state.fields[number].data}
            >
              <Form.Control
                label={label}
                type="date"
                defaultValue={this.state.fields[number].data}
              />
            </FloatingLabel>
          </Form.Group>
        ) : name === "ADR" ? (
          <>
            <Form.Group controlId={name} key={`form-group${number}`}>
              <button
                className="btn-outlined-color spy"
                type="button"
                onClick={() => {
                  this.changeActiveField(change_field, number);
                }}
              >
                <div className="spy-text">
                  {this.getAdrStringFromObj(
                    this.state.fields[number].data,
                    ", ",
                    ""
                  )}
                </div>
              </button>
            </Form.Group>
          </>
        ) : name === "TEL" || name === "EMAIL" || name === "URL" ? (
          <>
            <Form.Group
              controlId={name + number}
              key={`form-group${number}`}
              onChange={
                name === "TEL"
                  ? (event) =>
                      this.handleChangePhone(
                        event,
                        id_,
                        type_,
                        key_,
                        class_,
                        status_,
                        number
                      )
                  : name === "EMAIL"
                  ? (event) =>
                      this.handleChangeEmail(
                        event,
                        id_,
                        type_,
                        key_,
                        class_,
                        status_,
                        number
                      )
                  : name === "URL"
                  ? (event) =>
                      this.handleChangeURL(
                        event,
                        id_,
                        type_,
                        key_,
                        class_,
                        status_,
                        number
                      )
                  : () => {}
              }
            >
              <FloatingLabel
                controlId={name + number}
                label={label}
                className="card-form-input"
                value={this.state.fields[number].data}
              >
                <Form.Control
                  label={label}
                  type="text"
                  defaultValue={this.state.fields[number].data}
                  className={this.state.fields[number].class}
                />
              </FloatingLabel>
            </Form.Group>
          </>
        ) : (
          <Form.Group
            controlId={name}
            key={`form-group${number}`}
            onChange={(event) =>
              this.handleChangeField(event, id_, type_, key_, number)
            }
          >
            <FloatingLabel
              controlId={name}
              label={label}
              className="card-form-input"
              value={this.state.fields[number].data}
            >
              <Form.Control
                label={label}
                type="text"
                defaultValue={this.state.fields[number].data}
              />
            </FloatingLabel>
          </Form.Group>
        )}
      </div>
    );
  }

  Note(name, number) {
    let id_ = this.state.fields[number].id;
    let type_ = this.state.fields[number].type;
    let key_ = this.state.fields[number].keyName;

    let field_name = this.getFieldName(name);

    return (
      <>
        <Form.Group
          controlId={name}
          key={`form-group${number}`}
          onChange={(event) =>
            this.handleChangeNote(event, id_, type_, key_, number)
          }
        >
          <FloatingLabel
            controlId={name}
            label={field_name}
            className="card-form-input"
          >
            <TextareaAutosize
              onHeightChange={(rowHeight) => {
                this.rowHeight = rowHeight;
              }}
              minRows={this.note_params.minRows}
              maxRows={Infinity}
              className={this.state.fields[number].class}
              key={`form-control-${number}`}
              defaultValue={this.state.fields[number].data}
            />
          </FloatingLabel>
        </Form.Group>
      </>
    );
  }

  saveFile = async (blob) => {
    const a = document.createElement("a");
    a.download = "my-file.png";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  savedCropImage = (token, uid, toSend) => {
    if (this.props.editor) {
      this.props.editor.current?.getImageScaledToCanvas().toBlob(
        (blob) => {
          if (blob) {
            this.uploadNewImage(blob, token, uid, toSend);
          }
        },
        "image/jpeg",
        100
      );
    }
  };

  render() {
    const { change_field, change_number } = this.state;

    return (
      <>
        <ChangeFieldModal
          show={this.state.changeFieldModalShow}
          onHide={this.changeFieldModalClose.bind(this)}
          changeFieldFromModal={this.changeFieldFromModal.bind(this)}
          field={
            change_field !== undefined ? change_field : this.state.fields[0]
          }
          number={change_number !== undefined ? change_number : 0}
        />

        <AddFieldModal
          show={this.state.addFieldModalShow}
          onHide={this.addFieldModalClose.bind(this)}
          addFieldFromModal={this.addFieldFromModal.bind(this)}
          options={this.getOptions()}
        />

        <Form onSubmit={this.handleSubmit}>
          {this.Fields()}

          {this.state.status !== "" ? (
            <div className={this.state.text_class}>{this.state.status}</div>
          ) : (
            <></>
          )}

          <Form.Group>
            <button
              className="btn-outlined-color"
              type="button"
              onClick={this.addFieldModalOpen.bind(this)}
            >
              <span>
                <span className="add-field-plus-btn">
                  <FaPlus />
                </span>
                Add field
              </span>
            </button>
          </Form.Group>

          <Form.Group>
            {this.state.status !== "" ? (
              <button
                className="btn-outlined-dark low-margin-top"
                type="button"
                disabled
              >
                <>Save</>
              </button>
            ) : this.state.loading ? (
              <button className="btn-filled low-margin-top" type="submit">
                <span>
                  Save{" "}
                  <span className="loadIcon">
                    <BiLoader />
                  </span>
                </span>
              </button>
            ) : (
              <button className="btn-filled low-margin-top" type="submit">
                <>Save</>
              </button>
            )}
          </Form.Group>

          <Form.Group>
            <button
              className="btn-filled red low-margin-top"
              onClick={this.routeBack.bind(this)}
            >
              Cancel
            </button>
          </Form.Group>
        </Form>
      </>
    );
  }
}
