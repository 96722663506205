import React, { Component } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import welcomeImg from "../resources/img/welcome4.png";

export class WelcomeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <CloseButton variant="white" onClick={this.props.onHide} />
          <div className="modal-welcome-image">
            <div>
              <img
                className="welcome-image"
                src={welcomeImg}
                alt="user_image"
              />
            </div>
          </div>
          <div className="modal-status-auth white left">
            Congratulations on your purchase of the NFC business vCard!
          </div>

          <div className="modal-status-auth white left">
            Enter your contact data to use it. You can update your vCard at any
            time.
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
