import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactSession } from "react-client-session";

export class WriteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text_class: "modal-status-auth success",
      statusAuth: `Bring your card to sign...`,
    };
  }

  async writeCard() {
    ReactSession.setStoreType("sessionStorage");
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: "url", data: this.props.url }],
      });
      this.setState({
        text_class: "modal-status-auth success",
        statusAuth: `The card has been successfully written.`,
      });
      ReactSession.set("write_status", false);
      console.log(`The card has been successfully written.`);
    } catch (error) {
      this.setState({
        text_class: "modal-status-auth",
        statusAuth: `Write error.`,
      });
      ReactSession.set("write_status", false);
      console.log(`Write error: ${error}`);
    }
  }

  render() {
    ReactSession.setStoreType("sessionStorage");
    let temp_start = ReactSession.get("write_status");
    if (temp_start) {
      this.writeCard();
    }

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            NFC card writing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={this.state.text_class}>{this.state.statusAuth}</div>
        </Modal.Body>
      </Modal>
    );
  }
}
