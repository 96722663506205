import React, { Component } from "react";
import "../App.css";
import { Row, Col } from "react-bootstrap";
import { ReactSession } from "react-client-session";
import ImageUpload from "./img-upload";
import ChangeableFields from "./login-fields";
// import ImageCropper from "./img-cropper";

export default class ChangeCard extends Component {
  constructor(props) {
    super(props);

    this.state = { ...props.stat, isNewImage: false };
    this.token = ReactSession.get("user_token");
    this.liftingStatus = this.liftingStatus.bind(this);
    this.setNewImage = this.setNewImage.bind(this);
    this.setPhotoIdLift = this.setPhotoIdLift.bind(this);
    ReactSession.set("user_token", "");
    this.editor = React.createRef();
  }

  checkEmptyNames(infos) {
    let isName = false;
    let isSurname = false;
    let newFields = infos;

    if (infos.length !== 0) {
      infos.map((field) =>
        field.keyName === "NAME"
          ? (isName = true)
          : field.keyName === "SURNAME"
          ? (isSurname = true)
          : null
      );

      if (!isName || !isSurname) {
        if (!isName) {
          newFields.push({
            keyName: "NAME",
            type: "NAME",
            data: "",
          });
        }
        if (!isSurname) {
          newFields.push({
            keyName: "SURNAME",
            type: "SURNAME",
            data: "",
          });
        }
        infos = newFields;
      }
    } else {
      newFields.push({
        keyName: "NAME",
        type: "NAME",
        data: "",
      });
      newFields.push({
        keyName: "SURNAME",
        type: "SURNAME",
        data: "",
      });
    }
    infos = newFields;
    return infos;
  }

  liftingStatus(text, clss) {
    this.setState({
      text_class: clss,
      statusAuth: text,
    });
  }

  setPhotoIdLift(photoId) {
    this.setState({
      image: photoId,
    });
  }

  getFieldsForChange() {
    let infos = this.state.fields.infos;
    infos = this.checkEmptyNames(infos);
    let data = infos.map((field) =>
      field !== "" && field !== null
        ? field.keyName === "EMAIL" ||
          field.keyName === "TEL" ||
          field.keyName === "NOTE"
          ? Object.assign(field, {
              class: "form-control",
              status: false,
            })
          : field
        : null
    );
    return data;
  }

  setCenterOrStart() {
    if (
      this.state.fields.photoId !== null &&
      this.state.fields.photoId !== ""
    ) {
      document
        .getElementsByClassName("main-container")[0]
        .classList.add("aligh-item-start");
    } else {
      document
        .getElementsByClassName("main-container")[0]
        .classList.remove("aligh-item-start");
    }
  }

  setNewImage(image) {
    this.setState({
      isNewImage: true,
      blob_image: image,
    });
  }

  render() {
    ReactSession.setStoreType("sessionStorage");

    return (
      <div className="card-container">
        {this.setCenterOrStart()}
        <Row>
          <Col>
            <ImageUpload
              previousImage={
                this.state.fields.infos.length !== 0
                  ? this.state.fields.photoId
                  : ""
              }
              previousUrl={this.state.url}
              liftingStatus={this.liftingStatus}
              setNewImage={this.setNewImage}
              setPhotoIdLift={this.setPhotoIdLift}
              token={this.token}
              editor={this.editor}
            />

            <ChangeableFields
              fields={
                this.state.fields.infos[0] !== null
                  ? this.getFieldsForChange()
                  : []
              }
              loading={this.state.loading}
              getFieldName={this.props.getFieldName}
              photoId={this.state.image}
              token={this.token}
              liftingStatus={this.liftingStatus}
              setNewImage={this.setNewImage}
              setPhotoIdLift={this.setPhotoIdLift}
              editor={this.editor}
            />

            {this.state.statusAuth !== "" ? (
              <div className={this.state.text_class}>
                {this.state.statusAuth}
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
