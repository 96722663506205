import React from "react";
import { withTranslation } from "react-i18next";
import userIcon from "../../resources/img/icon-user.svg";
import likeIcon from "../../resources/img/icon-like.svg";
import phoneIcon from "../../resources/img/icon-phone.svg";
import AboutColumn from "./AboutColumn";

const About = () => {
  return (
    <div className="landing-about">
      <div className="about-row-outer">
        <AboutColumn
          key="first-column"
          icon={userIcon}
          title={"Landing.About.Text.Title1"}
          p1={"Landing.About.Text.Article1.P1"}
          p2={"Landing.About.Text.Article1.P2"}
        />

        <AboutColumn
          key="second-column"
          icon={phoneIcon}
          title={"Landing.About.Text.Title2"}
          p1={"Landing.About.Text.Article2.P1"}
          p2={"Landing.About.Text.Article2.P2"}
        />

        <AboutColumn
          key="third-column"
          icon={likeIcon}
          title={"Landing.About.Text.Title3"}
          p1={"Landing.About.Text.Article3.P1"}
          p2={"Landing.About.Text.Article3.P2"}
        />
      </div>
    </div>
  );
};

export default withTranslation()(About);
