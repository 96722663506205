import React, { useRef, useState } from "react";
import ReactAvatarEditor from "react-avatar-editor";
import "react-image-crop/dist/ReactCrop.css";
import "../App.css";
import { BiLoader } from "react-icons/bi";

const ImageUpload = (props) => {
  const inputFile = useRef(null);
  const [image, setImage] = useState(null);
  const [loading] = useState(false);
  const [url] = useState(props.previousUrl);

  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      // const filename = files[0].name;
      const file = files[0];

      // var parts = filename.split(".");
      // const fileType = parts[parts.length - 1];
      // console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = (e) => {
        setImage(reader.result);
      };
    }
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handlePositionChange = (position) => {
    setPosition(position);
  };

  const PureImage = React.memo(function PureImage({ src }) {
    return (
      <img className="img-url-center-notback" src={src} alt="user_image" />
    );
  });

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div>
      <input
        style={{ display: "none" }}
        accept=".png,.jpeg,.jpg"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />

      {url !== undefined && url !== "" && image === null ? (
        !loading ? (
          <div
            className="img-shadow margin-bottom-2"
            onClick={onButtonClick}
            type="button"
          >
            <PureImage src={url} />
          </div>
        ) : (
          <div className="img-url-center margin-bottom-2">
            <span className="loadIcon photoform">
              <BiLoader />
            </span>
          </div>
        )
      ) : image !== null ? (
        !loading ? (
          <>
            <div className="img-shadow" onClick={onButtonClick} type="button">
              <ReactAvatarEditor
                ref={props.editor}
                scale={parseFloat(scale)}
                width={500}
                height={500}
                position={position}
                onPositionChange={handlePositionChange}
                // rotate={parseFloat(this.state.rotate)}
                // borderRadius={this.state.width / (100 / this.state.borderRadius)}
                border={0}
                image={image}
                className="img-url-center-notback"
              />
            </div>
            <div className="zoom-title">Zoom:</div>
            <input
              name="scale"
              type="range"
              onChange={handleScale}
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
              className="zoom-style"
            />
          </>
        ) : (
          <div className="img-url-center margin-bottom-2">
            <span className="loadIcon photoform">
              <BiLoader />
            </span>
          </div>
        )
      ) : (
        <button className="non-photo-icon" onClick={onButtonClick}>
          {!loading ? (
            <></>
          ) : (
            <span className="loadIcon photoform margin-bottom-2">
              <BiLoader />
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default ImageUpload;
