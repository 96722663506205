import React, { Component } from "react";
import "../App.css";
import { Row, Col, Form, FloatingLabel } from "react-bootstrap";
import Config from "../config.js";
import { ReactSession } from "react-client-session";
import { FiCopy } from "react-icons/fi";
import { WriteModal } from "../components/write-modal";
import { SiNfc } from "react-icons/si";

export default class Goods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusAuth: "",
      loading: false,
      text_class: "adm-status-auth",
      url: "",
      writingModalShow: false,
    };
  }

  sendUidToBack(original_url, uid) {
    let token = `Bearer ${ReactSession.get("admin_token")}`;
    fetch(`${Config.BACKEND_IP}/api/short`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        originalUrl: original_url,
        uid: uid,
      }),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.url !== undefined) {
            this.setState({
              loading: false,
              text_class: "adm-status-auth success",
              statusAuth: "Link generation completed successfully.",
              url: result.url,
            });
            ReactSession.set("write_status", false);
            console.log("Link generation completed successfully.");
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Link compression error.`,
            });
            console.log(`Link compression error: ${result}`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `An unexpected error occurred while compressing the link.`,
          });
          console.log(
            `An unexpected error occurred while compressing the link: ${error}`
          );
        }
      );
  }

  componentDidMount() {
    ReactSession.setStoreType("sessionStorage");
    let token = `Bearer ${ReactSession.get("admin_token")}`;
    fetch(`${Config.BACKEND_IP}/api/validation-card`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.id !== undefined && result.id !== "") {
            this.sendUidToBack(
              `${Config.FRONTEND_IP}/c/${result.id}`,
              result.id
            );
            console.log("Link generated. Let's start squeezing...");
            this.setState({
              text_class: "adm-status-auth success",
              statusAuth: "Link generated. Let's start squeezing...",
            });
          } else {
            this.setState({
              loading: false,
              text_class: "adm-status-auth",
              statusAuth: `Error generating link.`,
            });
            console.log(`Error generating link: ${result}`);
          }
        },
        (error) => {
          this.setState({
            loading: false,
            text_class: "adm-status-auth",
            statusAuth: `An unexpected error occurred while generating the link.`,
          });
          console.log(
            `An unexpected error occurred while generating the link: ${error}`
          );
        }
      );
  }

  handleChangeUrl(event) {
    this.setState({ url: event.target.value });
  }

  copied() {
    this.setState({
      text_class: "adm-status-auth success",
      statusAuth: `Link copied to clipboard.`,
    });
  }

  not_copied(error) {
    this.setState({
      text_class: "adm-status-auth",
      statusAuth: `Copy error.`,
    });
    console.log(`Copy error: ${error}.`);
  }

  copyCard(event) {
    let link = this.state.url;
    event.preventDefault();
    try {
      navigator.clipboard.writeText(link);
      this.copied();
    } catch (error) {
      this.not_copied(error);
    }
  }

  logoutAdm() {
    ReactSession.setStoreType("sessionStorage");

    if (
      ReactSession.get("admin_token") === undefined ||
      ReactSession.get("admin_token") === ""
    ) {
      window.location.assign(`/AdminPanel/Authorization`);
    }
  }

  startWrite() {
    this.setState({
      writingModalShow: true,
    });
    ReactSession.set("write_status", true);
  }

  render() {
    let writingModalClose = () => {
      this.setState({ writingModalShow: false });
      ReactSession.set("write_status", false);
    };

    this.logoutAdm();
    return (
      <div className="main-container">
        {/* <BackButton/> */}

        <WriteModal
          show={this.state.writingModalShow}
          onHide={writingModalClose}
          status={this.state.statusAuth}
          url={this.state.url}
          start={this.state.start}
        />

        <div className="adm-actions-container">
          <Row>
            <Col>
              <div className="title-left-text-md3">
                Create a goods authenticity
              </div>

              <Row>
                <Col>
                  <Form autoComplete="off">
                    <Form.Group
                      controlId="url"
                      onChange={this.handleChangeUrl.bind(this)}
                    >
                      <FloatingLabel
                        controlId="url"
                        label="url"
                        className="adm-auth-form-input"
                        value={this.state.url}
                      >
                        <Form.Control
                          type="text"
                          placeholder="url"
                          disabled
                          value={this.state.url}
                        />
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                      <button
                        className="btn-outlined-color ml"
                        onClick={this.copyCard.bind(this)}
                      >
                        <span>
                          Copy link{" "}
                          <span>
                            <FiCopy />
                          </span>
                        </span>
                      </button>

                      {this.state.url ? (
                        <button
                          className="btn-filled ml"
                          type="button"
                          onClick={this.startWrite.bind(this)}
                        >
                          <span>Write NFC tag {<SiNfc />}</span>
                        </button>
                      ) : (
                        <button
                          className="btn-filled ml"
                          type="button"
                          disabled
                        >
                          <span>Write NFC tag {<SiNfc />}</span>
                        </button>
                      )}

                      {this.state.statusAuth !== "" ? (
                        <div className={this.state.text_class}>
                          {this.state.statusAuth}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
