import React from "react";
import ArdixLogo from "../../resources/img/vCard_white.svg";

import LanguageButton from "./LanguageButton";

const Header = () => {
  return (
    <div className="landing-header">
      <div className="header-row">
        <div className="header-column">
          <img id="logo" src={ArdixLogo} alt="logo"></img>
        </div>
        <div className="header-column item-row-end">
          <LanguageButton />
        </div>
      </div>
    </div>
  );
};

export default Header;
