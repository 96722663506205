import React, { Suspense } from "react";
import MainScreen from "./components/MainScreen";
import About from "./components/About";
import "./styles.css";
import Product from "./components/Product";
import Cube from "./components/Cube";
import Buy from "./components/Buy";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Preloader from "../components/Preloader";
import { Helmet } from "react-helmet";
import { withTranslation, useTranslation } from "react-i18next";

const Landing = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <html lang={t("HeadTag.HTML.Lang")}></html>
        <link rel="canonical" href={t("HeadTag.Link.Canonical")} />
        <meta name="description" content={t("HeadTag.Meta.Description")} />
        <title>{t("HeadTag.Title")}</title>
        <meta name="googlebot" content="index, follow" />
        <meta name="robots" content="index, follow" />
        <link rel="alternate" href="https://nfc.ardix.ua/" hreflang="uk" />
        <link rel="alternate" href="https://nfc.ardix.ua/en/" hreflang="en" />
      </Helmet>
      <Suspense fallback={<Preloader />}>
        <div className="landing-container">
          <Header />
          <MainScreen />
          <About />
          <Product />
          <Cube />
          <Buy />
          <Contact />
          <Footer />
        </div>
      </Suspense>
    </>
  );
};

export default withTranslation()(Landing);
