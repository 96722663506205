import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

const Buy = () => {
  const { t } = useTranslation();

  const routeToBuy = () => {
    window.location.href = "https://idcard.com.ua/ua/nfc-vizitka/";
  };

  return (
    <div className="landing-buy">
      <div className="buy-row">
        <div className="buy-column buy-text">
          <p id="buy-text-1" className="text-big">
            {t("Landing.Buy.Text.Title1")}
          </p>
          <h2 id="buy-text-2" className="text-big buy-stroke">
            {t("Landing.Buy.Text.Title2")}
          </h2>
          <p id="buy-text-3" className="text-big buy-stroke ">
            {t("Landing.Buy.Text.Title3")}
          </p>
        </div>
        <div className="buy-column buy-button">
          <button
            className="landing-button text-little"
            onClick={() => routeToBuy()}
            type="button"
            style={{ opacity: 1 }}
          >
            {t("Landing.Buy.Text.Button1")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Buy);
