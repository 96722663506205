import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import card from "../../resources/img/ardix-vcard-transparent-2.png";
import tags from "../../resources/img/nfc-vcard-tags-transparent.png";
// import miBand from "../../resources/img/miband.png";
// import ProductItem from "./ProductItem";

const Product = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-product">
      <div className="product-row-outer">
        <div className="product-column product-column-items">
          <div className="product-column product-column-card">
            <a href="https://idcard.com.ua/ua/nfc-vizitka/">
              <img src={card} id="product-item-card" alt="card" />
            </a>
          </div>
          <div className="product-column product-column-tags">
            <a href="https://idcard.com.ua/ua/nfc-vizitka/">
              <img src={tags} id="product-item-tags" alt="tags" />
            </a>
          </div>

          {/* <ProductItem image={miBand} />
          <ProductItem image={miBand} />
          <ProductItem image={miBand} />
          <ProductItem image={miBand} />
          <ProductItem image={miBand} />
          <ProductItem image={miBand} /> */}
        </div>
        <div className="product-column product-column-text">
          <div className="outer-light text-end">
            <p className="text-big product-text">
              {t("Landing.Product.Text.Title1.P1")}
              <span className="product-stroke">
                {t("Landing.Product.Text.Title1.P2")}
              </span>
              {t("Landing.Product.Text.Title1.P3")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Product);
