import React from "react";
import { useRef } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

const FloatingInput = (props) => {
  const value = useRef("");
  const className = useRef("contact-form text-form form-green");

  const handleChange = (event) => {
    if (event.target.value !== "") {
      className.current = "contact-form text-form form-green";
      value.current = event.target.value;
      props.setEmpty(false);
    } else {
      className.current = "contact-form  text-form form-red";
      value.current = event.target.value;
      props.setEmpty(true);
    }
  };

  return (
    <Form.Group controlId={props.id} onChange={(event) => handleChange(event)}>
      <FloatingLabel
        controlId={props.id}
        label={props.label}
        className="contact-input"
        value={value.current}
      >
        <Form.Control
          type={props.type}
          placeholder={props.label}
          className={className.current}
          required
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default FloatingInput;
