import React, { useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import FloatingInput from "./FloatingInput";
import useReRender from "../../hooks/useReRender";
import StatusMessage from "./StatusMessage";
import { BiLoader } from "react-icons/bi";
import Config from "../../config";

const ContactForm = () => {
  const { t } = useTranslation();

  const phoneEmpty = useRef(true);
  const nameEmpty = useRef(true);

  const render = useReRender();

  const [message, setMessage] = useState("");
  const messageClass = useRef("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    messageClass.current = "";
    setMessage("");
    setIsLoading(true);

    fetch(`${Config.BACKEND_IP}/api/message`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: event.target.tel.value,
        name: event.target.name.value,
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.json());
        else return response.json();
      })
      .then((data) => {
        messageClass.current = "text-green";
        setMessage(t("Landing.Message.Text.Success"));
      })
      .catch((data) => {
        messageClass.current = "text-red";
        setMessage(t("Landing.Message.Text.Error1") + data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setPhoneEmpty = (value) => {
    phoneEmpty.current = value;
    render();
  };

  const setNameEmpty = (value) => {
    nameEmpty.current = value;
    render();
  };

  return (
    <Form onSubmit={(event) => handleSubmit(event)}>
      <div className="contact-row-inner">
        <FloatingInput
          key="phoneNumber"
          id="tel"
          label={t("Landing.Contact.Text.Form1")}
          type="tel"
          setEmpty={(value) => setPhoneEmpty(value)}
        />
      </div>
      <div className="contact-row-inner">
        <FloatingInput
          key="name"
          id="name"
          label={t("Landing.Contact.Text.Form2")}
          type="text"
          setEmpty={(value) => setNameEmpty(value)}
        />
      </div>

      <div className="contact-row-inner">
        {phoneEmpty.current !== false || nameEmpty.current !== false ? (
          <button
            style={{ opacity: 1 }}
            type="button"
            disabled
            className="landing-button-disabled text-little"
          >
            {t("Landing.Contact.Text.Button1")}
          </button>
        ) : isLoading === false ? (
          <button
            type="submit"
            style={{ opacity: 1 }}
            className="landing-button text-little"
          >
            {t("Landing.Contact.Text.Button1")}
          </button>
        ) : (
          <button
            type="button"
            disabled
            className="landing-button-disabled text-little"
            style={{ opacity: 1 }}
          >
            <span>
              {t("Landing.Contact.Text.Button1")}
              <span className="loadIcon">
                {"    "}
                <BiLoader />
              </span>
            </span>
          </button>
        )}
      </div>
      <div className="contact-info-message">
        <StatusMessage message={message} className={messageClass.current} />
      </div>
    </Form>
  );
};

export default withTranslation()(ContactForm);
