import React, { Component } from "react";
import { CloseButton, Modal } from "react-bootstrap";

export class LimitModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="limit-modal"
      >
        <Modal.Body>
          <CloseButton variant="white" onClick={this.props.onHide} />
          <div className="modal-status-auth white center">
            The product may not be real
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
