import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import ContactForm from "./ContactForm";
import Info from "./Info";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-contact">
      <div className="contact-column-outer">
        <div className="contact-row contact-title">
          <p className="text-big">{t("Landing.Contact.Text.Title1")}</p>
        </div>
        <div className="contact-row contact-form">
          <ContactForm />
        </div>
        <div className="contact-row contact-article">
          <Info />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Contact);
