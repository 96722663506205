import React, { Component } from "react";
import { Modal, Form, FloatingLabel } from "react-bootstrap";

//модальное окно для изменения нового поля в визитку
//в состоянии только одно поле, посколько одновременно мы изменяем только одно
// пока функция заточена только под адрес, если нужно будет расширять - нужно дописывать
export class ChangeFieldModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: this.props.field,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // чтобы окно обновлялось при изменении props
  componentWillReceiveProps(nextProps) {
    this.setState({ field: nextProps.field });
  }

  handleSubmit(event) {
    event.preventDefault();

    let value = {
      address:
        event.target.address?.value !== null &&
        event.target.address?.value !== undefined
          ? event.target.address.value.trim()
          : "",
      city:
        event.target.city?.value !== null &&
        event.target.city?.value !== undefined
          ? event.target.city.value.trim()
          : "",
      region:
        event.target.region?.value !== null &&
        event.target.region?.value !== undefined
          ? event.target.region.value.trim()
          : "",
      postal_code:
        event.target.postal_code?.value !== null &&
        event.target.postal_code?.value !== undefined
          ? event.target.postal_code.value.trim()
          : "",
      country:
        event.target.country?.value !== null &&
        event.target.country?.value !== undefined
          ? event.target.country.value.trim()
          : "",
    };

    this.props.changeFieldFromModal(value, this.props.number);
    this.setState({
      field: {
        id: null,
        type: null,
        keyName: null,
        data: {
          address: "",
          city: "",
          region: "",
          postal_code: "",
          country: "",
        },
      },
    });
    this.props.onHide();
  }

  handleChangeAddress(event, subtype) {
    let temp_field = this.state.field;

    temp_field.data[subtype] = event.target.value;

    this.setState({
      field: temp_field,
    });
  }

  Address() {
    return (
      <>
        <Form.Group
          controlId="country"
          onChange={(event) => this.handleChangeAddress(event, "country")}
        >
          <FloatingLabel
            controlId="country"
            className="card-form-input"
            label="Country"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.country}
              placeholder="Country"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="region"
          onChange={(event) => this.handleChangeAddress(event, "region")}
        >
          <FloatingLabel
            controlId="region"
            className="card-form-input"
            label="Region"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.region}
              placeholder="Region"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="city"
          onChange={(event) => this.handleChangeAddress(event, "city")}
        >
          <FloatingLabel
            controlId="city"
            className="card-form-input"
            label="City"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.city}
              placeholder="City"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="postal_code"
          onChange={(event) => this.handleChangeAddress(event, "postal_code")}
        >
          <FloatingLabel
            controlId="postal_code"
            label="Postal code"
            className="card-form-input"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.postal_code}
              placeholder="Postal code"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group
          controlId="address"
          onChange={(event) => this.handleChangeAddress(event, "address")}
        >
          <FloatingLabel
            controlId="address"
            label="Street and house"
            className="card-form-input"
          >
            <Form.Control
              type="text"
              defaultValue={this.state.field.data.address}
              placeholder="Street and house"
            />
          </FloatingLabel>
        </Form.Group>
      </>
    );
  }

  render() {
    return (
      <div className="container modal">
        <Modal
          {...this.props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Change address
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              autoComplete="off"
              onSubmit={(event) => this.handleSubmit(event)}
            >
              {this.Address()}
              <Form.Group>
                <button
                  className="btn-outlined-color low-margin-top"
                  type="submit"
                >
                  <span>Save</span>
                </button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
