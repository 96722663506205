import React, { Component } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import Config from "../config";
import {
  BsFacebook,
  BsLinkedin,
  BsInstagram,
  BsTelegram,
  BsYoutube,
  BsWhatsapp,
  BsTwitter,
  BsLink45Deg,
} from "react-icons/bs";
import { FaViber, FaTiktok } from "react-icons/fa";
import "../App.css";

export default class NotChangeableFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.fields,
      status: "",
      text_class: "adm-status-auth",
    };
    this.note_params = {
      maxRows: 8,
      minRows: 1,
      count_symbols: 200,
    };
    this.getVcard = this.props.getVcard.bind(this);
  }

  routeToAuth(event) {
    event.preventDefault();
    window.location.assign(`/Authorization`);
  }

  getFieldName(name_field) {
    if (Config.FIELDS_NAMES[name_field] !== null) {
      return Config.FIELDS_NAMES[name_field];
    } else {
      return null;
    }
  }

  Fields() {
    return this.state.fields.map((field, number) =>
      field.keyName !== "NAME" &&
      field.keyName !== "SURNAME" &&
      field.keyName !== "PATRONYMIC" &&
      field.keyName !== "ORG" &&
      field.keyName !== "TITLE"
        ? this.getFieldName(field.keyName) !== null &&
          field.data !== "" &&
          field.data !== null
          ? this.Field(field.keyName, number)
          : null
        : null
    );
  }

  routeToLink(event, key_, type_, data) {
    event.preventDefault();
    if (key_ === "TEL") {
      data = data.replace(/[^+\d]/g, "");
    }
    const link = document.createElement("a");
    link.href =
      key_ === "SOCIAL"
        ? type_ === "FACEBOOK"
          ? `https://www.facebook.com/${data}`
          : type_ === "LINKEDIN"
          ? `https://www.linkedin.com/in/${data}`
          : type_ === "INSTAGRAM"
          ? `https://www.instagram.com/${data}`
          : type_ === "TELEGRAM"
          ? `https://t.me/${data}`
          : type_ === "YOUTUBE"
          ? data
          : type_ === "VIBER"
          ? `viber://chat?number=%2B${data}`
          : type_ === "WHATSAPP"
          ? `https://api.whatsapp.com/send/?phone=${data}`
          : type_ === "TWITTER"
          ? `https://twitter.com/${data}`
          : type_ === "TIKTOK"
          ? `https://tiktok.com/@${data}`
          : "*"
        : key_ === "URL"
        ? data
        : key_ === "ADR"
        ? `http://maps.google.com/maps?q=${data}`
        : key_ === "TEL"
        ? `tel:${data}`
        : key_ === "EMAIL"
        ? `mailto:${data}`
        : "*";
    link.click();
  }

  getLabel(key_, type_, field_name) {
    this.getFieldName();
    return key_ === "TEL" ||
      key_ === "EMAIL" ||
      key_ === "ADR" ||
      key_ === "URL" ||
      key_ === "BDAY" ||
      key_ === "ORG" ||
      key_ === "TITLE"
      ? field_name
      : key_ === "SOCIAL"
      ? type_ === "FACEBOOK"
        ? field_name + "Facebook"
        : type_ === "LINKEDIN"
        ? field_name + "LinkedIn"
        : type_ === "INSTAGRAM"
        ? field_name + "Instagram"
        : type_ === "TELEGRAM"
        ? field_name + "Telegram"
        : type_ === "YOUTUBE"
        ? field_name + "Youtube"
        : type_ === "VIBER"
        ? field_name + "Viber"
        : type_ === "WHATSAPP"
        ? field_name + "Whatsapp"
        : type_ === "TWITTER"
        ? field_name + "Twitter"
        : type_ === "TIKTOK"
        ? field_name + "TikTok"
        : field_name
      : field_name;
  }

  getType(key_) {
    return key_ === "EMAIL"
      ? "email"
      : key_ === "URL" || key_ === "SOCIAL"
      ? "url"
      : "text";
  }

  getIcon(type_) {
    return type_ === "FACEBOOK" ? (
      <BsFacebook />
    ) : type_ === "LINKEDIN" ? (
      <BsLinkedin />
    ) : type_ === "INSTAGRAM" ? (
      <BsInstagram />
    ) : type_ === "TELEGRAM" ? (
      <BsTelegram />
    ) : type_ === "YOUTUBE" ? (
      <BsYoutube />
    ) : type_ === "VIBER" ? (
      <FaViber />
    ) : type_ === "WHATSAPP" ? (
      <BsWhatsapp />
    ) : type_ === "TWITTER" ? (
      <BsTwitter />
    ) : type_ === "TIK-TOK" ? (
      <FaTiktok />
    ) : (
      <BsLink45Deg />
    );
  }

  isValidUrl(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  }

  Field(name, number) {
    let type_ = this.state.fields[number].type;
    let key_ = this.state.fields[number].keyName;
    let field_name = this.getFieldName(name);
    let label = this.getLabel(key_, type_, field_name);
    let type = this.getType(key_);

    if (key_ === "URL") {
      let url = this.state.fields[number].data;
      if (this.isValidUrl(url)) {
        let domain = undefined;
        try {
          domain = new URL(url).hostname;
        } catch (error) {
          console.error(error);
          return (
            <Form.Group key={`form-group${number}`}>
              <button className="btn-outlined-color spy" disabled>
                <div>
                  <span className="icon-btn-spy"></span>
                  <span>{"Incorrect url"}</span>
                </div>
              </button>
            </Form.Group>
          );
        }
        return (
          <Form.Group key={`form-group${number}`}>
            <button
              className="btn-outlined-color spy"
              onClick={(event) => this.routeToLink(event, key_, type_, url)}
            >
              <div>
                <span className="icon-btn-spy">
                  <BsLink45Deg />
                </span>
                <span>{domain}</span>
              </div>
            </button>
          </Form.Group>
        );
      } else {
        return (
          <Form.Group key={`form-group${number}`}>
            <button className="btn-outlined-color spy">
              <div>
                <span className="icon-btn-spy"></span>
                <span>{"Incorrect url"}</span>
              </div>
            </button>
          </Form.Group>
        );
      }
    } else if (key_ === "EMAIL" || key_ === "TEL" || key_ === "ADR") {
      let data = this.state.fields[number].data;

      return (
        <div key={`outer-form-group${number}`}>
          <Form.Group
            controlId={name}
            key={`form-group${number}`}
            onClick={(event) => this.routeToLink(event, key_, type_, data)}
          >
            <FloatingLabel
              controlId={name}
              label={label}
              className="card-form-input"
              key={`float-label-${number}`}
            >
              <Form.Control
                key={`form-control-${number}`}
                label={label}
                type={type}
                value={this.state.fields[number].data}
                disabled
              />
            </FloatingLabel>
          </Form.Group>
        </div>
      );
    } else if (key_ === "BDAY") {
      return (
        <div key={`outer-form-group${number}`}>
          <Form.Group controlId={name} key={`form-group${number}`}>
            <FloatingLabel
              controlId={name}
              label={label}
              className="card-form-input"
              key={`floating-label-${number}`}
            >
              <Form.Control
                type="date"
                label={label}
                value={this.state.fields[number].data}
                key={`form-control${number}`}
                disabled
              />
            </FloatingLabel>
          </Form.Group>
        </div>
      );
    } else if (key_ === "SOCIAL") {
      let url = this.state.fields[number].data;

      let icon = this.getIcon(type_);

      return (
        <Form.Group key={`form-group${number}`}>
          <button
            className="btn-outlined-color spy"
            onClick={(event) => this.routeToLink(event, key_, type_, url)}
          >
            <div className="spy-text">
              <span className="icon-btn-spy">{icon}</span>
              <span>{label}</span>
            </div>
          </button>
        </Form.Group>
      );
    } else if (key_ === "NOTE") {
      return (
        <div key={`outer-form-group${number}`}>
          <Form.Group controlId={name} key={`form-group${number}`}>
            <FloatingLabel
              key={`floating-label-${number}`}
              controlId={name}
              label={label}
              className="card-form-input"
            >
              <TextareaAutosize
                minRows={this.note_params.minRows}
                maxRows={this.note_params.maxRows}
                className="form-control"
                key={`form-control-${number}`}
                label={label}
                value={this.state.fields[number].data}
                disabled
              />
            </FloatingLabel>
          </Form.Group>
        </div>
      );
    } else {
      return (
        <div key={`outer-form-group${number}`}>
          <Form.Group controlId={name} key={`form-group${number}`}>
            <FloatingLabel
              key={`floating-label-${number}`}
              controlId={name}
              label={label}
              className="card-form-input"
            >
              <Form.Control
                key={`form-control-${number}`}
                label={label}
                type={type}
                value={this.state.fields[number].data}
                disabled
              />
            </FloatingLabel>
          </Form.Group>
        </div>
      );
    }
  }

  Work() {
    let company = "";
    let position = "";

    this.state.fields.map((field) =>
      field.keyName === "ORG" &&
      field.data !== "" &&
      field.data !== null &&
      field.data !== undefined
        ? (company = field.data)
        : field.keyName === "TITLE" &&
          field.data !== "" &&
          field.data !== null &&
          field.data !== undefined
        ? (position = field.data)
        : null
    );

    let comma = company !== "" && position !== "" ? "," : "";
    return (
      <div className="work-of-user">{`${company.trimEnd()}${comma} ${position.trimEnd()}`}</div>
    );
  }

  Name() {
    let name = "";
    let surname = "";
    let patronymic = "";

    this.state.fields.map((field) =>
      field.keyName === "NAME" &&
      field.data !== "" &&
      field.data !== null &&
      field.data !== undefined
        ? (name = field.data)
        : field.keyName === "SURNAME" &&
          field.data !== "" &&
          field.data !== null &&
          field.data !== undefined
        ? (surname = field.data)
        : field.keyName === "PATRONYMIC" &&
          field.data !== "" &&
          field.data !== null &&
          field.data !== undefined
        ? (patronymic = field.data)
        : null
    );

    return (
      <div className="name-of-user">{`${name} ${patronymic} ${surname} `}</div>
    );
  }

  render() {
    return (
      <>
        <Form autoComplete="off">
          {this.props.count_fields !== 0 ? (
            <>
              {this.Name()}
              {this.Work()}
              {this.Fields()}
            </>
          ) : (
            <div className="empty-card-text">
              <div className="title-left-text">
                Looks like the card hasn't been completed yet.
              </div>

              <div className="description-left-text">
                If you are the owner of the card - click the edit button and
                fill it out.
              </div>
            </div>
          )}

          {this.props.isVCard ? (
            <button
              className="btn-filled btn-fixed low-margin-top"
              onClick={this.getVcard}
            >
              Add to contacts
            </button>
          ) : (
            <></>
          )}

          <Form.Group>
            <button
              className="btn-outlined-color"
              onClick={this.routeToAuth.bind(this)}
            >
              Edit
            </button>
          </Form.Group>
        </Form>
      </>
    );
  }
}
